import React, {useState} from 'react';
import gql from 'graphql-tag';
import {useQuery} from 'urql';
import {useLoadingState} from '../../partials/LoadingProvider';
import SearchView from './search-view';
import {Sheet} from './types';
import getSelectValues from './utils/getSelectValues';

const query = gql`
  {
    taxonomies {
      name
    }
    usedProductComponents
  }
`;

const searchQuery = gql`
  query(
    $keywords: [String]
    $components: [String]
    $months: Int
    $sheets: [String]
  ) {
    findProducts(
      keywords: $keywords
      components: $components
      months: $months
      sheets: $sheets
    ) {
      id
      name
      duration
      months
      components
      taxonomy {
        name
      }
      sheets {
        id
        name
      }
      url
      score
      matchedComponents
      price
      ibb
      noSell
    }
  }
`;

const SearchContainer: React.FC = () => {
  const [sheet, setSheet] = useState<Sheet | null>(null);
  const [keywords, setKeywords] = useState([]);
  const [comps, setComponents] = useState<string[]>([]);
  const [months, setMonths] = useState(0);

  const [{data: components, fetching: fetchingComponents}] = useQuery({
    query: query,
    variables: {},
  });

  const [{data: resultsData, fetching: fetchingResults, error}] = useQuery({
    query: searchQuery,
    variables: {
      keywords: keywords,
      components: comps,
      months: months,
      sheets: sheet ? [sheet.id] : null,
    },
    pause: false,
  });

  useLoadingState(fetchingComponents || fetchingResults);

  return (
    <SearchView
      results={resultsData?.findProducts}
      onKeywordsKeyUp={ev =>
        //@ts-ignore - there is something wrong with the typescript definition, and it does not see the target value, so ignore it
        setKeywords(ev.target.value.split(/ +/).filter(val => !!val))
      }
      onMonthsChange={ev => setMonths(parseInt(ev.target.value))}
      usedProductComponents={components?.usedProductComponents}
      onProductComponentsChange={ev => {
        setComponents(getSelectValues(ev.target));
      }}
      //
      // sheet related props
      //
      selectedSheet={sheet}
      onSheetClick={(ev, sheet) => {
        ev.preventDefault();
        setSheet(sheet);
      }}
      onCloseSelectedSheetClick={ev => {
        ev.preventDefault();
        setSheet(null);
      }}
    />
  );
};

export default SearchContainer;
