import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect, useRef} from 'react';
import {SigningDoneProps} from './types';
import cn from 'classnames';
import {useLoadingState} from '../../../partials/LoadingProvider';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';

const checkSignatureMutation = gql`
  mutation($event: String!, $offer: ID!, $envelope: String!) {
    checkSignature(offer: $offer, event: $event, envelope: $envelope)
  }
`;

const redirect = (where: any) => {
  window.location = where;
};

const SigningDone: React.FC<SigningDoneProps> = props => {
  const {} = props;
  const [data, setData] = useState({event: '', envelope: '', offer: ''});
  const [working, setWorking] = useState(false);
  const [error, setError] = useState(false);
  const loc = document.location + '';

  const [checkSignatureResult, checkSignature] = useMutation(
    checkSignatureMutation,
  );

  const fetchUpdate = async (item?: any) => {
    if (working) return;
    setWorking(true);

    const what: any = item || data;

    try {
      const result = await checkSignature({...what});
      if (result.error) {
        setError(true);
      }

      if (result.data?.checkSignature) {
        redirect('/warenkorb/');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  useEffect(() => {
    const envelope = (loc.match(/envelope=([^\&]+)/) || [])[1];
    const event = (loc.match(/event=([^\&]+)/) || [])[1];
    const offer = (loc.match(/offer=([^\&]+)/) || [])[1];

    setData({envelope, event, offer});

    if (event && event === 'signing_complete') {
      if (offer && envelope) {
        setInterval(() => fetchUpdate({envelope, event, offer}), 30000);
      }

      fetchUpdate({envelope, event, offer});
    }
  }, [loc]);

  useLoadingState(false);

  return (
    <>
      <div className="Home">
        <div className="container-fluid pt-5" role="main">
          <div className="container">
            <div
              style={{
                background: 'url(/images/potential-hero.jpg) center 850px',
                height: '300px',
                opacity: 0.7,
                marginBottom: '1rem',
              }}
            />
            <br />
            <div
              hidden={
                !(!!data.event && data.event === 'signing_complete') || error
              }>
              <h2>
                <img src="/images/loading.svg" style={{width: '2rem'}} /> Wir
                verarbeiten Ihre Daten ...
              </h2>
            </div>

            <div hidden={!(data.event !== 'signing_complete') && !error}>
              <h2>
                <i className="fa fa-exclamation-circle" /> Bei der Verarbeitung
                trat ein Fehler auf, bitte kontaktieren Sie unsere
                Karriereberatung.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigningDone;
