import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {PotentialViewProps, Selection} from './types';
import {Package} from './variant/types';
import {Offer} from '../types';
import OfferView from '../offer';
import VariantView from './variant';
import SearchView from './search';
import CompleteView from './complete';
import BGSView from './bgs';
import SelfView from './self';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';

import cn from 'classnames';
import {Portal} from 'react-portal';

gql`
  input PackageInput {
    id: ID!
    maybe: Boolean
  }
`;

const updateDataMutation = gql`
  mutation(
    $id: ID!
    $bgsId: String!
    $zip: String!
    $street: String!
    $city: String!
  ) {
    completePotentialData(
      id: $id
      bgsId: $bgsId
      zip: $zip
      street: $street
      city: $city
    )
  }
`;

const updateStartDateMutation = gql`
  mutation($id: ID!, $date: Date!) {
    updatePotentialStartDate(id: $id, date: $date)
  }
`;

const createOffersMutation = gql`
  mutation($id: ID!, $packages: [PackageInput!]) {
    createPotentialOffers(id: $id, packages: $packages) {
      id
    }
  }
`;

const selectPotentialOfferMutation = gql`
  mutation($potentialId: ID!, $offerId: ID!) {
    selectPotentialOffer(potentialId: $potentialId, offerId: $offerId)
  }
`;

const signOfferMutation = gql`
  mutation($id: ID!, $privacyAccepted: Boolean!, $contractAccepted: Boolean!) {
    signOffer(
      id: $id
      privacyAccepted: $privacyAccepted
      contractAccepted: $contractAccepted
    )
  }
`;

const PotentialView: React.FC<PotentialViewProps> = props => {
  const {potential, reloadData, isSales, working, setWorking} = props;
  const [completedData, setCompletedData] = useState<any>({
    bgsId: potential.bgs?.id || '',
    street: potential.contact?.address?.street || '',
    city: potential.contact?.address?.city || '',
    zip: potential.contact?.address?.zip || '',
  });

  const [showCompleteData, setShowCompleteData] = useState(false);
  const [creatingOffers, setCreatingOffers] = useState(false);
  const [selection, setSelection] = useState<Selection>('none');
  const hasOffers =
    potential.offers?.filter(
      offer =>
        !(offer.status === 'rejected' || (isSales && offer.status === 'maybe')),
    ).length > 0;
  const [products, setProducts] = useState<Array<Package>>([]);
  const [createOffersResult, createOffers] = useMutation(createOffersMutation);
  const [selectPotentialOfferResult, selectPotentialOffer] = useMutation(
    selectPotentialOfferMutation,
  );
  const [signOfferResult, signOffer] = useMutation(signOfferMutation);
  const [updateDataResult, updateData] = useMutation(updateDataMutation);
  const [updateStartDateResult, updateStartDate] = useMutation(
    updateStartDateMutation,
  );

  const [selecting, setSelecting] = useState(false);

  const accepted = potential.offers?.filter(
    offer =>
      offer && (offer.status === 'accepted' || offer.status === 'signed'),
  )?.[0];
  const ableToAccept =
    !isSales &&
    !accepted &&
    potential.passedEignung &&
    potential.passedKBEignung &&
    potential.haveBGS &&
    potential.haveCV;

  const realOffer = potential.passedEignung && potential.passedKBEignung;
  const hasContact = !!(
    potential?.contact?.address?.zip &&
    potential?.contact?.address?.street &&
    potential?.contact?.address?.city
  );
  const ableToBuy = !!accepted && !isSales;
  const showSearch = !(hasOffers || creatingOffers) || (isSales && !accepted);

  const onSetStartDate = async (what: string) => {
    setWorking(true);
    if (what) {
      try {
        const result = await updateStartDate({id: potential.id, date: what});
        if (result.error) {
          throw new Error(result.error.message);
        }

        reloadData();
      } catch (e) {
        console.log(e);
      } finally {
        setWorking(false);
      }
    }
  };

  const completeData = () => {
    if (isSales) return;

    window.scrollTo(0, 0);
    setShowCompleteData(true);
  };

  const saveCompletedData = async () => {
    setWorking(true);
    if (
      completedData.bgsId &&
      completedData.street &&
      completedData.city &&
      completedData.zip
    ) {
      try {
        const result = await updateData({id: potential.id, ...completedData});
        if (result.error) {
          throw new Error(result.error.message);
        }

        setShowCompleteData(false);

        reloadData();
      } catch (e) {
        console.log(e);
      } finally {
        setWorking(false);
      }
    }
  };

  const doSignOffer = async (
    offer: any,
    privacyAccepted: boolean,
    contractAccepted: boolean,
  ) => {
    if (!privacyAccepted || !contractAccepted) return;
    setWorking(true);
    try {
      const result = await signOffer({
        id: offer.id,
        privacyAccepted,
        contractAccepted,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }

      if (result.data?.signOffer) {
        window.location = result.data.signOffer;
      } else {
        await reloadData();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  const doSelectPotentialOffer = async (offer: any) => {
    setWorking(true);
    try {
      const result = await selectPotentialOffer({
        potentialId: potential.id,
        offerId: offer.id,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }

      await reloadData();
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  useEffect(() => {
    if (products.length) {
      setWorking(true);
      const potentialProducts = [...products];
      setProducts([]);
      setCreatingOffers(true);
      setSelection('none');

      (async () => {
        try {
          const result = await createOffers({
            id: potential.id,
            packages: potentialProducts.map(product => ({
              id: product.id,
              maybe: product.maybe,
            })),
          });
          if (result.error) {
            throw new Error(result.error.message);
          }
          potential.offers = potential.offers || [];
          potential.offers = [
            ...potential.offers,
            ...result.data.createPotentialOffers,
          ];

          setCreatingOffers(false);
          await reloadData();
        } catch (e) {
          console.log(e);
          setCreatingOffers(false);
          setWorking(false);
        }
      })();
    }
  }, [products.length]);

  return (
    <div>
      {creatingOffers && (
        <h2>
          <img src="/images/loading.svg" style={{width: '2rem'}} /> Wir
          verarbeiten Ihre Anfrage ...
        </h2>
      )}
      {showCompleteData && (
        <CompleteView
          {...props}
          {...{
            setShowCompleteData,
            setCompletedData,
            saveCompletedData,
            completedData,
          }}
        />
      )}
      {showSearch && !creatingOffers && (
        <SearchView
          {...props}
          {...{
            hasOffers,
            selection,
            setSelection,
            accepted,
            setProducts: what => setProducts(what),
          }}
        />
      )}

      <div style={{marginTop: '2rem'}}>
        {hasOffers && (
          <div>
            <h2>
              <span hidden={!isSales}>
                {potential.contact?.salutation} {potential.contact?.firstName}{' '}
                {potential.contact?.lastName}
              </span>
            </h2>

            {hasOffers && !accepted && potential.type === 'AZAV' && (
              <BGSView {...props} {...{onSetStartDate}} />
            )}
            {hasOffers &&
              !accepted &&
              potential.type === 'Selbstzahler' &&
              false && <SelfView {...props} {...{onSetStartDate}} />}
          </div>
        )}
        {hasOffers && accepted ? (
          <div>
            <OfferView
              selecting={selecting}
              setSelecting={setSelecting}
              reloadData={reloadData}
              payment={potential?.payment}
              isSales={isSales}
              completeData={completeData}
              hasContact={hasContact}
              ableToAccept={ableToAccept}
              realOffer={realOffer}
              ableToBuy={ableToBuy}
              selectOffer={() => null}
              signOffer={(
                offer: Offer,
                privacyAccepted: boolean,
                contractAccepted: boolean,
              ) => doSignOffer(offer, privacyAccepted, contractAccepted)}
              key={accepted.id}
              offer={accepted}
              start={potential.start}
            />
          </div>
        ) : (
          <div
            style={{
              marginTop: '-5rem',
              display: 'grid',
              gridTemplateColumns: isSales
                ? 'repeat(1, 1fr)'
                : 'repeat(3, 1fr)',
              gridGap: '2rem',
              gridAutoRows: 'minmax(100px, auto)',
            }}>
            {potential.offers
              ?.filter(
                offer =>
                  offer &&
                  (offer.status === 'open' ||
                    offer.status === 'accepted' ||
                    (isSales && offer.status === 'maybe')),
              )
              .map(offer => (
                <OfferView
                  selecting={selecting}
                  setSelecting={setSelecting}
                  reloadData={reloadData}
                  isSales={isSales}
                  completeData={completeData}
                  hasContact={hasContact}
                  realOffer={realOffer}
                  ableToAccept={ableToAccept}
                  ableToBuy={ableToBuy}
                  selectOffer={offer => doSelectPotentialOffer(offer)}
                  signOffer={() => null}
                  key={offer.id}
                  offer={offer}
                  start={potential.start}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PotentialView;
