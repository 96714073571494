import {Formik, FormikHelpers} from 'formik';
import React, {useState, useEffect} from 'react';
import {useLoadingState} from '../../partials/LoadingProvider';
import CustomerPotentialsView from './view';
import {CustomerPotentialsFormValues} from './types';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';
import useUser, {isLoading} from '../../../user/useUser';
import * as Yup from 'yup';

const potentialQuery = gql`
  query($id: ID) {
    potentials(id: $id) {
      id
      haveBGS
      haveCV
      haveEignung
      haveKBEignung
      passedEignung
      passedKBEignung
      start
      status
      type
      payment {
        primary {
          percent
          amount
          type
        }
        secondary {
          percent
          amount
          type
        }
      }
      bgs {
        id
        start
        dkz
        durationInMonth
      }
      contact {
        id
        email
        salutation
        firstName
        lastName
        address {
          street
          city
          zip
        }
      }
      offers {
        id
        price
        status
        package {
          id
          title
          url
          duration
          months
          link
          ibb
          noSell
          components
          sheets
        }
        products {
          id
          sku
          title
          price
        }
      }
    }
  }
`;

const CustomerPotentialsContainer: React.FC = (arg: any) => {
  const potentialId = arg?.match?.params?.id;
  const user = useUser() as any;
  const isSales =
    user?.type === 'employee' &&
    (user.roles.includes('Karriereberatung') ||
      user.roles.includes('graph-developer') ||
      user.roles.includes('graph-admin'));
  const [working, setWorking] = useState<boolean>(false);

  const [{data, fetching}, reexecuteQuery] = useQuery({
    requestPolicy: 'network-only',
    query: potentialQuery,
    variables: {id: potentialId},
  });

  useLoadingState(fetching || working);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!working) reexecuteQuery();
    }, 10000);
    return () => clearInterval(interval);
  }, [working]);

  //
  // form validation rules
  //
  const validationSchema = Yup.object().shape({
    //appName: Yup.string().required('Required'),
  });

  //
  // Finalize the form
  //
  async function handleSubmit(
    values: CustomerPotentialsFormValues,
    actions: FormikHelpers<CustomerPotentialsFormValues>,
  ) {}

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <CustomerPotentialsView
          {...formikProps}
          working={working}
          setWorking={(what: boolean) => setWorking(what)}
          isSales={isSales}
          reloadData={async () => {
            await reexecuteQuery();
            setWorking(false);
          }}
          potentials={data?.potentials}
        />
      )}
    </Formik>
  );
};

export default CustomerPotentialsContainer;
