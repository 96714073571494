import React from 'react';
import {User} from '../user/types';

/**
 *
 * undefined -> loading
 * null -> not logged in
 * User -> actual user
 *
 */
export const UserContext = React.createContext<User | null | undefined>(
  undefined,
);
