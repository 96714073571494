import React from 'react';
import {InvitedHomeViewProps} from './types';
import '../Home/Home.css';

const InvitedHomeView: React.FC<InvitedHomeViewProps> = props => {
  return (
    <div className="Home">
      <main className="fullwidth" role="main">
        <div className="container">
          <h1 style={{marginTop: '8rem', marginBottom: '2rem'}}>
            Meine Warenkorb
          </h1>
          <p>Hier finden Sie ihre Produkte:</p>
        </div>
      </main>
    </div>
  );
};

export default InvitedHomeView;
