import React, {useState} from 'react';
import TimetableView from './timetable-view';
import gql from 'graphql-tag';
import {useQuery} from 'urql';
import {DateTime} from 'luxon';
import {useLoadingState} from '../../partials/LoadingProvider';
import {TimetableProps, QueryData, TimetableSlot} from './types';

const query = gql`
  query($componentId: ID!) {
    timetableForComponent(id: $componentId) {
      id
      name
      duration
      start
      end
      slots {
        name
        start
        end
        link
        group
        type
        firstWeek
        lastWeek
        break
      }
    }
  }
`;

const TimetableContainer: React.FC<TimetableProps> = props => {
  const id = props.match?.params?.id;

  const [selectedGroup, setSelectedGroup] = useState('1');
  const [{data, fetching}] = useQuery<QueryData>({
    query: query,
    variables: {componentId: id},
  });

  const isReady = useLoadingState(fetching);

  const groups: {[key: string]: number} = {};

  (data?.timetableForComponent?.slots ?? []).forEach(event => {
    groups[event.group.toString()] = 1;
  });

  const eventsFiltered = (
    data?.timetableForComponent?.slots?.map(slot => {
      slot.title = slot.break ? slot.name : slot.type + ': ' + slot.name;

      return slot;
    }) || []
  ).filter(event => event.group.toString() === selectedGroup || !event.group);
  const startItems: TimetableSlot[] = [];
  const finishItems: TimetableSlot[] = [];
  const items: TimetableSlot[] = [];

  if (data?.timetableForComponent) {
    const timetable = data?.timetableForComponent;
    const then = DateTime.fromJSDate(new Date(timetable.start));
    const now = DateTime.fromJSDate(new Date());

    const diff = now.diff(then, ['weeks', 'days']).toObject();

    const weeks = diff.weeks ?? 0;
    if (weeks >= timetable.duration) {
      // this moves the timetable to the current iteration
      const offsetWeeks = Math.max(
        timetable.duration,
        Math.floor(weeks / timetable.duration) * timetable.duration,
      );

      [...eventsFiltered].forEach(event => {
        eventsFiltered.push({
          ...event,
          start: DateTime.fromJSDate(new Date(event.start))
            .plus({weeks: offsetWeeks})
            .toJSDate()
            .toISOString(),
          end: DateTime.fromJSDate(new Date(event.end))
            .plus({weeks: offsetWeeks})
            .toJSDate()
            .toISOString(),
        });
      });
    }
  }

  eventsFiltered.forEach(event => {
    if (event.firstWeek) {
      startItems.push(event);
    } else if (event.lastWeek) {
      finishItems.push(event);
    } else {
      items.push(event);
    }
  });

  const events = [...startItems].concat(items).concat(finishItems);

  return (
    <TimetableView
      isReady={isReady}
      onGroupClick={setSelectedGroup}
      events={events}
      groups={groups}
      timetableForComponent={data?.timetableForComponent}
    />
  );
};

export default TimetableContainer;
