import {useState} from 'react';
import LoadingContext from './loading-context';
import {LoadingProviderProps} from './types';

/**
 *
 * Handle managing the overall app loading state
 *
 * @param {*} props
 */
const LoadingProvider: React.FC<LoadingProviderProps> = props => {
  const [isReady, setIsReady] = useState(false);

  // we care both about the parent loading
  // but also make the children load in the background
  // so we need to wait for those components
  const isLoading = props.loading || !isReady;

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setIsReady,
      }}>
      {isLoading && <>{props.fallback}</>}

      {/*
       * include everything in a hidden div so react will render it, and do the required data fetching
       * thus, we are prerendering the three
       */}
      <div hidden={isLoading}>{props.children}</div>
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
