import React from 'react';
import {Link} from 'react-router-dom';
import {useLoadingState} from '../../partials/LoadingProvider';

const NotFoundView: React.FC = () => {
  useLoadingState(false);

  return (
    <div className="Home error404">
      <main
        id="content"
        className="container-fluid content-404 overflow-hidden"
        role="main">
        <div className="container">
          <div className="row kt-404-text">
            <div className="col-12 col-md-5">
              <h1>Seite nicht gefunden</h1>
              <p>
                Diese Seite existiert nicht. Hier geht es zur{' '}
                <Link to={`/`}>Startseite</Link>
              </p>
              <div>
                <Link to={`/`} className="kt-btn kt-primary mb-4">
                  Startseite
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-404-image">
          <img id="kt-404-img-main" src="/images/404.png" />
          <img
            id="kt-404-img1"
            className="kt-404-image-element"
            src="/images/stift.png"
          />
          <img
            id="kt-404-img2"
            className="kt-404-image-element"
            src="/images/Smartphone.png"
          />
          <img
            id="kt-404-img3"
            className="kt-404-image-element"
            src="/images/Kopfhoerer.png"
          />
          <img
            id="kt-404-img4"
            className="kt-404-image-element-big"
            src="/images/Laptop.png"
          />
          <img
            id="kt-404-img5"
            className="kt-404-image-element"
            src="/images/brille.png"
          />
          <img
            id="kt-404-img6"
            className="kt-404-image-element"
            src="/images/Cookie.png"
          />
          <img
            id="kt-404-img7"
            className="kt-404-image-element-big"
            src="/images/Notizbuch.png"
          />
        </div>
      </main>
    </div>
  );
};

export default NotFoundView;
