import React, {useState} from 'react';
import {RegisterViewProps} from './types';
import {ErrorMessage, Field, Form} from 'formik';
import cn from 'classnames';

const RegisterView: React.FC<RegisterViewProps> = props => {
  const {registrationDone, isSubmitting, getFieldMeta, errors, touched} = props;

  const [showRegistration, setShowRegistration] = useState<boolean>(false);
  //only show the full form when we have a valid email adress
  const emailField = getFieldMeta('email');

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <div
      className={cn('kt-login kt-box col-11 col-md-6 offset-md-1 py-4', {
        'alert alert-success': registrationDone,
      })}>
      {!registrationDone ? (
        <Form className="needs-validation">
          <div className="row px-2">
            <div
              className={cn('kt-form col-md-12', {
                error: touched.email && errors.email,
              })}>
              <h4 className="mb-0">Registrieren</h4>
              <div>Noch kein Account? Hier registrieren.</div>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-envelope input-prefix"></i>
                <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                <Field
                  type="email"
                  name="email"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  aria-required="true"
                  aria-invalid="false"
                  onKeyUp={() =>
                    setShowRegistration(
                      (!emailField.error &&
                        ((emailField.value || '') as string).match(
                          /\.\w\w+$/,
                        )) as boolean,
                    )
                  }
                />
                <label>Meine E-Mail</label>
              </div>
              <ErrorMessage
                name="email"
                render={msg => <div className="error-message mb-1">{msg}</div>}
              />
            </div>
          </div>

          {showRegistration && (
            <div className="kt-registration-container row px-2">
              <div
                className={cn('kt-form col-md-12', {
                  error: touched.salutation && errors.salutation,
                })}>
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-user-tie input-prefix"></i>
                  <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                  <Field
                    as="select"
                    name="salutation"
                    className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required form-control"
                    style={{
                      borderRadius: '2rem',
                      paddingTop: '0.7rem',
                      paddingBottom: '0.7rem',
                      border: '2px solid #ccc',
                    }}
                    aria-required="true"
                    aria-invalid="false">
                    <option value=""></option>
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                    <option value="Divers">Divers</option>
                  </Field>
                  <label></label>
                </div>
                <ErrorMessage
                  name="salutation"
                  render={msg => (
                    <div className="error-message mb-1">{msg}</div>
                  )}
                />
              </div>

              <div
                className={cn('kt-form col-md-12', {
                  error: touched.firstName && errors.firstName,
                })}>
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-user-tie input-prefix"></i>
                  <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                  <Field
                    type="text"
                    name="firstName"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    aria-required="true"
                    aria-invalid="false"
                  />
                  <label>Vorname</label>
                </div>
                <ErrorMessage
                  name="firstName"
                  render={msg => (
                    <div className="error-message mb-1">{msg}</div>
                  )}
                />
              </div>

              <div
                className={cn('kt-form col-md-12', {
                  error: touched.lastName && errors.lastName,
                })}>
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-user-tie input-prefix"></i>
                  <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                  <Field
                    type="text"
                    name="lastName"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    aria-required="true"
                    aria-invalid="false"
                  />
                  <label>Nachname</label>
                </div>
                <ErrorMessage
                  name="lastName"
                  render={msg => (
                    <div className="error-message mb-1">{msg}</div>
                  )}
                />
              </div>

              <div
                className={cn('kt-form col-md-12', {
                  error: touched.password && errors.password,
                })}>
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-key input-prefix"></i>
                  <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                  <Field
                    type={isRevealPwd ? 'text' : 'password'}
                    name="password"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    aria-required="true"
                    aria-invalid="false"
                  />
                  <label>Passwort</label>
                </div>
                <ErrorMessage
                  name="password"
                  render={msg => (
                    <div className="error-message mb-1">{msg}</div>
                  )}
                />
              </div>

              <div
                className={cn('kt-form col-md-12', {
                  error: touched.passwordConfirm && errors.passwordConfirm,
                })}>
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-key input-prefix"></i>
                  <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                  <Field
                    type={isRevealPwd ? 'text' : 'password'}
                    name="passwordConfirm"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    aria-required="true"
                    aria-invalid="false"
                  />
                  <label>Passwort wiederholen</label>
                </div>
                <ErrorMessage
                  name="passwordConfirm"
                  render={msg => (
                    <div className="error-message mb-1">{msg}</div>
                  )}
                />
              </div>
              <div style={{textAlign: 'right', width: '100%'}}>
                <i
                  className={!isRevealPwd ? 'fa fa-eye' : 'fa fa-eye-slash'}
                  style={{color: '#666'}}
                />{' '}
                <a
                  onClick={ev => {
                    ev.preventDefault();
                    setIsRevealPwd(!isRevealPwd);
                  }}
                  style={{
                    fontSize: '12px',
                    color: '#999',
                    marginRight: '20px',
                  }}>
                  Passwörter {!isRevealPwd ? 'anzeigen' : 'verstecken'}
                </a>
              </div>
              <div className="md-outline mt-2">
                <p>
                  <small>
                    Alle Informationen zum Thema Datenschutz und darüber wie wir
                    Ihre Daten verarbeiten finden Sie in unseren{' '}
                    <a
                      href="https://www.karrieretutor.de/datenschutz/"
                      target="_blank">
                      <small>Datenschutzhinweisen</small>
                    </a>
                    .
                  </small>
                </p>
                <div
                  className={cn('kt-form col-md-12', {
                    error: touched.termsAccepted && errors.termsAccepted,
                  })}>
                  <p className="px-1">
                    <small>
                      <Field
                        type="checkbox"
                        name="termsAccepted"
                        style={{
                          position: 'static',
                          opacity: 1,
                          pointerEvents: 'auto',
                        }}
                      />{' '}
                      Ich akzeptiere die{' '}
                      <a
                        href="https://www.karrieretutor.de/agb/"
                        target="_blank"
                        style={{fontSize: '1rem'}}>
                        Allgemeinen Geschäftsbedingungen
                      </a>
                      .
                      <ErrorMessage
                        name="termsAccepted"
                        render={msg => (
                          <div className="error-message mt-1 pl-0">{msg}</div>
                        )}
                      />
                    </small>
                  </p>
                </div>
                <div
                  className={cn('kt-form col-md-12', {
                    error: touched.newsletter && errors.newsletter,
                  })}>
                  <p className="px-1">
                    <small>
                      <Field
                        type="checkbox"
                        name="newsletter"
                        style={{
                          position: 'static',
                          opacity: 1,
                          pointerEvents: 'auto',
                        }}
                      />{' '}
                      Ich erkläre mich mit der Verarbeitung meiner Daten zum
                      Zwecke der Kontaktaufnahme und Zusendung von regelmäßigen
                      News &amp; Trends in der Weiterbildung und Arbeitswelt,
                      Veranstaltungen, speziellen Angeboten und Umfragen von der
                      karriere tutor GmbH per E-Mail einverstanden.
                      <ErrorMessage
                        name="newsletter"
                        render={msg => (
                          <div className="error-message mt-1 pl-0">{msg}</div>
                        )}
                      />
                    </small>
                  </p>
                </div>
              </div>
              <div className="md-outline"></div>
            </div>
          )}

          <div className="submit-row px-2">
            <input
              className="kt-btn kt-secondary kt-xs-center"
              type="submit"
              value={
                isSubmitting
                  ? 'Wir bearbeiten Ihre Registrierung ...'
                  : 'Jetzt registrieren!'
              }
              disabled={isSubmitting}
              style={{opacity: !showRegistration ? 0.3 : 1}}
            />
          </div>
        </Form>
      ) : (
        <div className="row">
          <div className="col-12">
            <h4 className="alert-heading">
              Vielen Dank für Ihre Registrierung.
            </h4>
            <p className="px-0">Vielen Dank für Ihre Registrierung!</p>
            <p className="px-0">
              Wir haben Ihnen eine E-Mail zugeschickt. Bitte prüfen Sie Ihr
              E-Mail-Postfach.
            </p>
            <p className="px-0">
              Falls Sie keine E-Mail mit dem Betreff{' '}
              <strong>„Aktivierung I mein karriere tutor“</strong> erhalten
              haben, sehen Sie bitte in Ihrem Spam-Ordner nach.
            </p>
            <p className="px-0">
              Mit dem Klick auf den in der E-Mail enthaltenen Bestätigungslink
              aktivieren Sie Ihren Account.
            </p>
            <p className="px-0">
              Sie haben auch im Spam-Ordner keine E-Mail von uns?
            </p>
            <p className="px-0">Dann wenden Sie sich bitte an den Support:</p>

            <p className="px-0">
              E-Mail:{' '}
              <a href="mailto:support@karrieretutor.de">
                support@karrieretutor.de
              </a>
            </p>
            <p className="px-0">
              Telefon: <a href="tel:+49 341 392 93 494">+49 341 392 93 494</a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterView;
