import React from 'react';
import {EmployeeHomeViewProps} from './types';
import NewsContainer from '../../partials/News';

import './EmployeeHome.css';

const EmployeeHomeView: React.FC<EmployeeHomeViewProps> = props => {
  const {user} = props;

  return (
    <div className="Home">
      <main role="main ">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 py-4">
              <h1>Hallo {user?.firstName}!</h1>

              <img
                style={{
                  marginTop: '2rem',
                  maxHeight: '300px',
                  clipPath:
                    'polygon(5% 5%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)',
                }}
                src="/images/kt-Microsoft-Teams-Hintergrund-1.jpg"
              />
              <p style={{marginTop: '2rem'}}>
                Dein neues Hintergrundbild für Teams/Zoom. Einfach{' '}
                <code>Rechtsklick</code> auf das Bild und dann{' '}
                <code>Bild speichern unter</code>.
              </p>
            </div>
            <div
              className="kt-box col-12 col-md-4 py-4"
              style={{maxHeight: '500px'}}>
              <NewsContainer />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EmployeeHomeView;
