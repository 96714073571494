import {FormikProps} from 'formik';
import {Offer, PotentialPayment} from '../types';
import React, {useState, useEffect, Dispatch} from 'react';

export type Document = {
  id: string;
  name: string;
  type: string;
  content: string;
};

export const status = {
  open: 'Offen',
  accepted: 'Ausgewählt',
  rejected: 'Abgelehnt',
  maybe: 'Vorgemerkt',
  signed: 'Unterschrieben',
};

export type OfferViewProps = /*FormikProps<OfferFormValues> &*/ {
  offer: Offer;
  ableToAccept: boolean;
  ableToBuy?: boolean;
  selectOffer: (offer: Offer) => void;
  signOffer: (
    offer: Offer,
    privacyAccepted: boolean,
    contractAccepted: boolean,
  ) => void;
  realOffer: boolean;
  hasContact: boolean;
  payment?: {primary: PotentialPayment; secondary: PotentialPayment};
  completeData: () => void;
  reloadData: () => void;
  isSales: boolean;
  start: string;
  selecting: boolean;
  setSelecting: (what: boolean) => void;
};

export type ViewProps = OfferViewProps & {
  downloading: string | null;
  setDownloading: Dispatch<string | null>;
  isAccepted: boolean;
  isSigned: boolean;
  hasSecondaryPayment: boolean;
  doSelectOffer: (ev: any, offer: any) => void;
  downloadOffer: () => void;
  downloadContract: () => void;
  selectingThis: Offer | null;
};

export type OfferFormValues = {};
