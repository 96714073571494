import {useContext, useLayoutEffect} from 'react';
import LoadingContext from './loading-context';

export default function useLoadingState(componentIsLoading?: boolean) {
  const {isLoading: appIsLoading, setIsReady} = useContext(LoadingContext);

  /**
   * when the UI is ready, we simply stop the loading state
   */
  useLayoutEffect(() => {
    if (!componentIsLoading) {
      setIsReady(true);
    }
  }, [componentIsLoading]);

  const isReady = !appIsLoading;
  return isReady;
}
