import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {BGSViewProps} from './types';
import {Package} from './variant/types';
import {Offer} from '../types';
import OfferView from '../offer';
import VariantView from './variant';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';
import cn from 'classnames';
import {Portal} from 'react-portal';
import BGSViewContainer from './bgsprocess';

const View: React.FC<BGSViewProps> = props => {
  const {
    potential,
    reloadData,
    isSales,
    working,
    setWorking,
    onSetStartDate,
  } = props;

  return (
    <>
      <h2 hidden={isSales} style={{marginTop: '2rem'}}>
        Ihre Weiterbildungen
      </h2>
      <p hidden={isSales}>
        <i className="fas fa-question-circle"></i> Sie haben Fragen? Wir haben
        die{' '}
        <a href="https://www.karrieretutor.de/faq/" target="_blank">
          Antworten
        </a>
        !
      </p>
      <div
        hidden={false}
        style={{
          zIndex: -2,
          position: 'absolute',
          left: 0,
          backgroundColor: '#f4f5f7',
          padding: '1rem',
          height: '350px',
          width: '30%',
          display: 'flex',
        }}></div>
      <img
        style={{
          position: 'absolute',
          left: '45%',
          width: '400px',
          marginTop: '-6rem',
          opacity: '0.7',
        }}
        src="https://www.karrieretutor.de/wp-content/uploads/2020/11/Grafik_Graduation_Cap.png"
        alt="Die Illustration eines Absolventenhutes steht für den erfolgreichen Abschluss einer Online-Weiterbildung bei karriere tutor®."
      />
      <div
        style={{
          background:
            "url('https://www.karrieretutor.de/wp-content/plugins/kt-styleguide/frontend/assets/images/form-roundedr.jpg') no-repeat left",
          backgroundSize: 'contain',
          paddingTop: '3rem',
          display: 'flex',
          height: '350px',
        }}>
        <BGSViewContainer
          onSetStartDate={onSetStartDate}
          setWorking={setWorking}
          isSales={isSales}
          potential={potential}
        />
      </div>
    </>
  );
};

export default View;
