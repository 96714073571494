import React, {useState} from 'react';
import {NewsViewProps, NewsItem} from './types';
import SanitizedHTML from '../../utils/sanitize-html';
import {Portal} from 'react-portal';

const NewsView: React.FC<NewsViewProps> = props => {
  const {news, showLink} = props;

  const pad = (what: number) => {
    return what < 10 ? '0' + what : what + '';
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    return (
      pad(d.getDate()) + '.' + pad(d.getMonth() + 1) + '.' + d.getFullYear()
    );
  };

  const [current, setCurrent] = useState<NewsItem | null>(null);

  return (
    <>
      {current && (
        <Portal>
          <div
            style={{
              position: 'absolute',
              overflow: 'auto',
              height: '100%',
              maxHeight: '100%',
              background: 'white',
              top: '6rem',
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 10000,
              padding: '0 6rem',
            }}>
            <a onClick={() => setCurrent(null)} style={{float: 'right'}}>
              Schliessen
            </a>
            <div className="container">
              <h1>{current.title}</h1>
              <p>{formatDate(current.date)}</p>
              <br />
              <div style={{fontSize: '1.2rem', textAlign: 'justify'}}>
                {current.image ? (
                  <div
                    style={{
                      marginLeft: '2em',
                      marginBottom: '2em',
                      maxWidth: '400px',
                      display: 'block',
                      float: 'right',
                    }}>
                    <img src={'data:image/*;base64,' + current.image} />
                  </div>
                ) : null}
                <SanitizedHTML
                  allowedAttributes={{a: ['href', 'target']}}
                  allowedTags={[
                    'a',
                    'b',
                    'strong',
                    'div',
                    'p',
                    'ul',
                    'ol',
                    'li',
                    'table',
                    'thead',
                    'tbody',
                    'tr',
                    'td',
                    'div',
                    'br',
                  ]}
                  html={current.content}
                />
              </div>
            </div>
          </div>
        </Portal>
      )}
      <div
        style={{
          fontSize: '1.1rem',
          overflow: 'auto',
          maxHeight: '100%',
          padding: '30px',
          paddingTop: '0px',
        }}>
        <h3 style={{padding: 0, textAlign: 'center', marginBottom: '2rem'}}>
          <i className="far fa-info" style={{color: '#FFB743'}} />{' '}
          {showLink ? (
            <a
              style={{fontSize: '2rem'}}
              href="https://creatorapp.zoho.eu/karrieretutorgmbh/news#Report:Alle_News"
              target="_blank">
              News
            </a>
          ) : (
            'News'
          )}
        </h3>
        {news?.map(item => (
          <div key={item.id} style={{marginBottom: '2rem'}}>
            <h5 style={{fontSize: '1.4rem', paddingLeft: '0px'}}>
              {item.url ? (
                <i className="far fa-newspaper"></i>
              ) : (
                <i className="far fa-exclamation"></i>
              )}{' '}
              {item.title}
            </h5>

            {item.teaser && (
              <SanitizedHTML
                allowedAttributes={{a: ['href', 'target']}}
                allowedTags={[
                  'a',
                  'b',
                  'strong',
                  'div',
                  'p',
                  'ul',
                  'ol',
                  'li',
                  'table',
                  'thead',
                  'tbody',
                  'tr',
                  'td',
                  'div',
                  'br',
                ]}
                html={item.teaser}
              />
            )}
            <br />
            {item.url ? (
              <a href={item.url} target="_blank" style={{fontSize: '1.1rem'}}>
                <i className="far fa-chevron-right" /> Mehr erfahren
              </a>
            ) : null}
            {!item.url ? (
              <a
                href="#"
                onClick={() => setCurrent(item)}
                style={{fontSize: '1.1rem'}}>
                <i className="far fa-chevron-right" /> Mehr erfahren
              </a>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default NewsView;
