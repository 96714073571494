import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {CompleteViewProps, Selection} from './types';
import {Package} from './variant/types';
import {Offer} from '../types';
import OfferView from '../offer';
import VariantView from './variant';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';
import cn from 'classnames';
import {Portal} from 'react-portal';

const View: React.FC<CompleteViewProps> = props => {
  const {
    potential,
    reloadData,
    isSales,
    working,
    setWorking,
    setShowCompleteData,
    completedData,
    saveCompletedData,
    setCompletedData,
  } = props;

  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          overflow: 'auto',
          height: '100%',
          maxHeight: '100%',
          background: 'white',
          top: '6rem',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          padding: '0 6rem',
        }}>
        <a onClick={() => setShowCompleteData(false)} style={{float: 'right'}}>
          Schliessen
        </a>
        <div className="container">
          <h1>Bitte vervollständigen Sie Ihre Angaben</h1>
          <p></p>
          <br />
          <form>
            <div className="kt-form">
              <h3>Ihr Bildungsgutschein</h3>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-user input-prefix"></i>
                <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                <input
                  value={completedData.bgsId}
                  onChange={ev =>
                    setCompletedData({
                      ...completedData,
                      bgsId: ev.target.value,
                    })
                  }
                  type="text"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
                <label className="active">Bildungsgutscheinnummer</label>
              </div>
              <h3>Ihre Adresse</h3>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-user input-prefix"></i>
                <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                <input
                  value={completedData.street}
                  onChange={ev =>
                    setCompletedData({
                      ...completedData,
                      street: ev.target.value + '',
                    })
                  }
                  type="text"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
                <label className="active">Straße</label>
              </div>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-user input-prefix"></i>
                <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                <input
                  value={completedData.city}
                  onChange={ev =>
                    setCompletedData({
                      ...completedData,
                      city: ev.target.value + '',
                    })
                  }
                  type="text"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
                <label className="active">Stadt</label>
              </div>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-user input-prefix"></i>
                <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                <input
                  min="0"
                  max="99999"
                  value={completedData.zip || ''}
                  onChange={ev =>
                    setCompletedData({
                      ...completedData,
                      zip:
                        (ev.target.value + '')
                          .replace(/[^\d]+/g, '')
                          .substr(0, 5) || '',
                    })
                  }
                  type="text"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  aria-required="true"
                  aria-invalid="false"
                />
                <label className="active">Postleitzahl</label>
              </div>
            </div>
            <a
              onClick={() => saveCompletedData()}
              className="my-3 kt-btn kt-primary kt-xs-center">
              Speichern
            </a>
            &nbsp;
            <a
              onClick={() => setShowCompleteData(false)}
              style={{
                paddingTop: '2rem',
                paddingRight: '1rem',
                float: 'right',
              }}>
              Abbrechen
            </a>
          </form>
        </div>
      </div>
    </Portal>
  );
};

export default View;
