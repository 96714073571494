import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {SearchViewProps, Selection} from './types';
import {Package} from './variant/types';
import {Offer} from '../types';
import OfferView from '../offer';
import VariantView from './variant';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';
import cn from 'classnames';
import {Portal} from 'react-portal';

const View: React.FC<SearchViewProps> = props => {
  const {
    potential,
    reloadData,
    isSales,
    working,
    setWorking,
    setProducts,
    hasOffers,
    accepted,
    selection,
    setSelection,
  } = props;

  return (
    <div>
      <br /> <br />
      {selection === 'none' && (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridGap: '2rem',
              gridAutoRows: 'minmax(100px, auto)',
            }}>
            <div className="card" style={{textAlign: 'center'}}>
              <img
                className="card-img-top"
                src="/images/Weiterbildung-bei-karriere-tutor-beginnen-1.jpg"
                alt=""
              />
              <div className="card-body">
                <h5 className="card-title">Bildungsgutschein</h5>
                <p className="card-text">
                  Sie möchten sich mit einem Bildungsgutschein eine erfolgreiche
                  Karriere sichern?
                </p>
              </div>
              <div
                className="card-footer text-muted"
                style={{textAlign: 'center'}}>
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    setSelection('AZAV');
                  }}
                  className="kt-btn kt-primary">
                  Jetzt Zukunft sichern!
                </a>
              </div>
            </div>

            <div className="card" hidden={false} style={{textAlign: 'center'}}>
              <img
                className="card-img-top"
                src="/images/Weiterbildung-lohnt-sich.jpg"
                alt=""
              />
              <div className="card-header">Jetzt neu!</div>
              <div className="card-body">
                <h5 className="card-title">Interaktive Beratung</h5>
                <p className="card-text">
                  Sie möchten am liebsten alles erfahren, was es rund um das
                  Thema Weiterbildung bei karriere tutor® zu erfahren gibt?
                </p>
              </div>
              <div
                className="card-footer text-muted"
                style={{textAlign: 'center'}}>
                <a
                  href="https://www.karrieretutor.de/faq/"
                  target="_blank"
                  className="kt-btn kt-primary">
                  Jetzt starten!
                </a>
              </div>
            </div>

            {/* TODO selbstzahler*/}
            <div className="card" hidden={true} style={{textAlign: 'center'}}>
              <img
                className="card-img-top"
                src="/images/Weiterbildung-lohnt-sich.jpg"
                alt=""
              />
              <div className="card-header">Brandneue Kurse</div>
              <div className="card-body">
                <h5 className="card-title">Selbstzahler</h5>
                <p className="card-text">
                  Möchten Sie gemeinsam mit karriere tutor &reg; in eine
                  selbstbestimmte Zukunft starten?
                </p>
              </div>
              <div
                className="card-footer text-muted"
                style={{textAlign: 'center'}}>
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    setSelection('Selbstzahler');
                  }}
                  className="kt-btn kt-primary">
                  Jetzt starten!
                </a>
              </div>
            </div>
            <div
              className="card"
              hidden={isSales}
              style={{textAlign: 'center'}}>
              <img
                className="card-img-top"
                src="/images/Ganzheitliche-persoenliche-KARRIEREBERATUNG-1-1.jpg"
                alt=""
              />
              <div className="card-body">
                <h5 className="card-title">Karriereberatung</h5>
                <p className="card-text">
                  Sie wünschen Beratung?
                  <br />
                  <br />
                  <a href="https://www.karrieretutor.de/faq/" target="_blank">
                    Online
                  </a>{' '}
                  <br /> <small>oder</small> <br /> 0800 711 410 200
                </p>
              </div>
              <div
                className="card-footer text-muted"
                style={{textAlign: 'center'}}>
                <a href="tel:0800711410200" className="kt-btn kt-secondary">
                  Rufen Sie uns an!
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {selection !== 'none' && (
        <div>
          <a
            href="#"
            onClick={ev => {
              ev.preventDefault();
              setSelection('none');
            }}>
            Zurück
          </a>
          <VariantView
            isSales={isSales}
            selection={selection}
            onSelectPackages={packages =>
              setProducts((old: Array<Package>) => [...packages])
            }
          />
        </div>
      )}
    </div>
  );
};

export default View;
