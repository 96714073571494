import React from 'react';
import {ReactComponent as AnimatedLogo} from './logo_kt.svg';

export default function LoadingView() {
  return (
    <div className="container-fluid position-absolute fixed-top">
      <div className="row align-items-center modal-dialog-centered">
        <div className="col text-center">
          <AnimatedLogo className="loadingLogo" />
        </div>
      </div>
    </div>
  );
}
