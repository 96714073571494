import React from 'react';
import YouTube from 'react-youtube';
import {HomeViewProps} from './types';
import NewsContainer from '../../partials/News';

import './Home.css';

const HomeView: React.FC<HomeViewProps> = props => {
    return (
        <main className="Home" role="main">

            <div className="container-fluid d-none d-md-flex mb-5" style={{
                backgroundImage: 'url("https://www.karrieretutor.de/wp-content/uploads/2021/11/meinkt-bannerbild.jpg")',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="container align-self-center my-5 py-5">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <h1 className="m-0 p-0 pb-5">Willkommen!</h1>
                            <p className="p-0 m-0">
                                <strong>
                                    Hier erreichen Sie ab dem Starttermin Ihrer Weiterbildung unsere Lernplattform mit
                                    Ihrem Lehrgang bzw. Ihren Lehrg&auml;ngen.
                                </strong>
                            </p>
                            <div className="d-block py-4">
                                <a href="https://karrieretutor.avendoo.de/l/samlLogin?sso=samlUser"
                                   className="mb-3 kt-btn kt-primary kt-xs-center" target="_blank" rel="noindex">
                                    Zur Lernplattform
                                </a>
                            </div>
                            <p style={{fontSize: '1em'}} className="p-0 m-0">
                                <strong>Hinweis:</strong> Klicken Sie bereits vor dem Start Ihrer Weiterbildung auf den
                                Button zur Lernplattform, erscheint die Anzeige <strong>„Registration failed“</strong>.
                                Der Grund daf&uuml;r ist, dass die Lernplattform erst ab Ihrem pers&ouml;nlichen
                                Starttermin f&uuml;r Sie freigeschaltet ist.
                            </p>
                        </div>
                        <div className="col-12 col-md-6 offset-md-1">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://player.vimeo.com/video/651472980?h=93f0cb1e1c&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    style={{border: 0}}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                />
                            </div>
                            <h3 className="pt-3">Video-Tutorial: Ihre Lernplattform</h3>
                            <p style={{fontSize: '1em'}} className="p-0 m-0">
                                Bevor Sie Ihren Lehrgang starten, lernen Sie mithilfe des Tutorials Ihre
                                Lernplattform mit den verschiedenen Inhalten und Funktionen kennen. Das Tutorial
                                zeigt Ihnen unter anderem, wie Sie auf Ihren Lehrgang zugreifen, wie Sie sich
                                f&uuml;r Termine anmelden und welche kostenlosen Zusatzangebote auf Ihrer
                                Lernplattform zu finden sind.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 d-block d-md-none " style={{backgroundColor: '#E8EFF4'}}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="m-0 p-0 pb-5">Willkommen!</h1>

                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://player.vimeo.com/video/651472980?h=93f0cb1e1c&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    style={{border: 0}}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                />
                            </div>
                            <h3 className="pt-3">Video-Tutorial: Ihre Lernplattform</h3>
                            <p style={{fontSize: '1em'}} className="p-0 m-0">
                                Bevor Sie Ihren Lehrgang starten, lernen Sie mithilfe des Tutorials Ihre
                                Lernplattform mit den verschiedenen Inhalten und Funktionen kennen. Das Tutorial
                                zeigt Ihnen unter anderem, wie Sie auf Ihren Lehrgang zugreifen, wie Sie sich
                                f&uuml;r Termine anmelden und welche kostenlosen Zusatzangebote auf Ihrer
                                Lernplattform zu finden sind.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pb-5 d-block d-md-none">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-12 pt-5">
                            <p className="p-0 m-0">
                                <strong>
                                    Hier erreichen Sie ab dem Starttermin Ihrer Weiterbildung unsere Lernplattform mit
                                    Ihrem Lehrgang bzw. Ihren Lehrg&auml;ngen.
                                </strong>
                            </p>
                            <div className="d-block py-4">
                                <a href="https://karrieretutor.avendoo.de/l/samlLogin?sso=samlUser"
                                   className="mb-3 kt-btn kt-primary kt-xs-center" target="_blank" rel="noindex">
                                    Zur Lernplattform
                                </a>
                            </div>
                            <p style={{fontSize: '1em'}} className="p-0 m-0">
                                <strong>Hinweis:</strong> Klicken Sie bereits vor dem Start Ihrer Weiterbildung auf den
                                Button zur Lernplattform, erscheint die Anzeige <strong>„Registration failed“</strong>.
                                Der Grund daf&uuml;r ist, dass die Lernplattform erst ab Ihrem pers&ouml;nlichen
                                Starttermin f&uuml;r Sie freigeschaltet ist.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 pb-4">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    src="https://player.vimeo.com/video/533991826?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                    style={{border: 0}}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h3>Kostenloses Bewerber-Coaching</h3>
                            <p>
                                Mit unseren kostenlosen Online-Trainings wie z. B. dem Bewerbercoaching oder dem
                                Coaching "Pr&uuml;fungen erfolgreich meistern" holst du noch mehr aus dir heraus.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-5 b-block d-sm-none">
                <div className="container p-0">
                    <div className="row p-0">
                        <div className="col-12 p-0">
                            <img
                                src="https://www.karrieretutor.de/wp-content/uploads/2021/11/meinkt-selbsttest-mobile.jpg"
                                style={{width: '100%', maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-1 order-md-2 d-none d-sm-block pb-4">
                            <img src="https://www.karrieretutor.de/wp-content/uploads/2021/11/meinkt-selbsttest.jpg"
                                 style={{width: '100%', maxWidth: '100%'}}/>
                        </div>
                        <div className="col-12 col-md-5 order-md-1">
                            <h3>Selbsttest zur Nutzung unseres virtuellen Klassenraums</h3>
                            <p>
                                Bitte f&uuml;hren Sie vor dem Start Ihrer Weiterbildung den Selbsttest zur Nutzung des
                                virtuellen Klassenraums "Adobe Connect" durch. Gehen Sie dazu in diesen {' '}
                                <a href="https://adobeconnect.karrieretutor.de/testraum2/?proto=true" target="_blank">
                                    Testraum
                                </a>. Geben Sie auf der Einstiegsseite bitte Ihren <strong>Vor- und
                                Nachnamen</strong> an und betreten Sie den Raum als <strong>"Gast"</strong>!
                            </p>

                            <p>
                                Der Testlink entspricht <strong>nicht</strong> dem Link für Ihre
                                Einf&uuml;hhrungsveranstaltung. Im Testraum werden Sie anhand einer kurzen Beschreibung
                                Schritt für Schritt durch den Nutzungstest gef&uuml;hrt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-5 b-block d-sm-none">
                <div className="container p-0">
                    <div className="row p-0">
                        <div className="col-12 p-0">
                            <img
                                src="https://www.karrieretutor.de/wp-content/uploads/2021/11/meinkt-begleitmaterial-mobile.jpg"
                                style={{width: '100%', maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 d-none d-sm-block pb-4">
                            <img
                                src="https://www.karrieretutor.de/wp-content/uploads/2021/11/meinkt-begleitmaterial.jpg"
                                style={{width: '100%', maxWidth: '100%'}}/>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h3>Ihr Begleitmaterial zum Lehrgang</h3>
                            <p>
                                In einigen Lehrg&auml;ngen erhalten unsere Teilnehmer Fachb&uuml;cher als
                                zus&auml;tzliches Begleitmaterial. Diese senden wir den jeweiligen Teilnehmern direkt
                                nach der Anmeldung per Post zu. Gerade bei kurzfristigen Anmeldungen ist es jedoch
                                m&ouml;glich, dass die Unterlagen erst nach Kursstart bei Ihnen eintreffen. Daf&uuml;r
                                bitten wir Sie um Verst&auml;ndnis.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </main>
    )
        ;
};

export default HomeView;
