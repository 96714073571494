import React from 'react';
import LoadingProvider from '../App/partials/LoadingProvider';
import LoadingView from '../App/partials/LoadingView';
import {UserContext} from '../context/user';
import {useQuery} from 'urql';
import gql from 'graphql-tag';

const MeQuery = gql`
  query {
    me {
      id
      type
      firstName
      lastName
      email
      name
      type
      roles
      status
      profile
      settings
      hasActiveBooking
      news {
        id
        title
        teaser
        content
        date
        url
        image
      }
    }
  }
`;

const UserProvider: React.FC = props => {
  const [result, reexecuteQuery] = useQuery({
    query: MeQuery,
    variables: {},
  });

  const {data, fetching, error} = result;
  const isAnonymous = error && error?.response?.status === 401;

  return (
    <UserContext.Provider value={fetching ? undefined : data?.me ?? null}>
      <LoadingProvider
        loading={fetching || (error != undefined && !isAnonymous)}
        fallback={<LoadingView />}>
        {props.children}
      </LoadingProvider>
    </UserContext.Provider>
  );
};

export default UserProvider;
