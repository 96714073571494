import {ErrorMessage, Field, Form} from 'formik';
import React, {useState} from 'react';
import {CustomerPotentialsViewProps} from './types';
import PotentialView from './potential';
import cn from 'classnames';

// import '../EmployeeHome/EmployeeHome.css';

const CustomerPotentialsView: React.FC<CustomerPotentialsViewProps> = props => {
  const {
    potentials,
    touched,
    errors,
    reloadData,
    isSales,
    working,
    setWorking,
  } = props;
  const [visible, setVisible] = useState(false);

  return (
    <div className="Home">
      <main className="container-fluid pt-1" role="main">
        <div className="container">
          {potentials?.map(potential => (
            <PotentialView
              working={working}
              setWorking={setWorking}
              isSales={isSales}
              reloadData={reloadData}
              key={potential.id}
              potential={potential}
            />
          ))}
        </div>
      </main>
    </div>
  );
};

export default CustomerPotentialsView;
