import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {OfferViewProps, Document} from './types';
import {Offer} from '../types';
import ProductView from '../product';
import cn from 'classnames';
//@ts-ignore
import party from 'party-js';

import CustomerView from './customer';
import SalesView from './sales';

import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';

const downloadDocument = gql`
  query($id: ID!, $type: DocumentType!) {
    document(id: $id, type: $type) {
      id
      name
      type
      content
    }
  }
`;

const download = async (doc: Document) => {
  const linkSource = `data:${doc.type};base64,${doc.content}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = doc.name;
  downloadLink.click();
};

const OfferView: React.FC<OfferViewProps> = props => {
  const {
    payment,
    offer,
    selectOffer,
    signOffer,
    ableToAccept,
    realOffer,
    ableToBuy,
    hasContact,
    completeData,
    isSales,
    reloadData,
    start,
    selecting,
    setSelecting,
  } = props;

  const [doDownload, setDoDownload] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<string | null>(null);
  const [selectingThis, setSelectingThis] = useState<Offer | null>(null);
  const isAccepted = offer.status === 'accepted' || offer.status === 'signed';
  const isSigned = offer.status === 'signed';
  const hasSecondaryPayment =
    isAccepted &&
    payment?.secondary?.type != null &&
    payment?.secondary?.amount > 0;

  const [result, reexecuteQuery] = useQuery({
    query: downloadDocument,
    variables: {id: offer.id, type: doDownload},
    pause: true,
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (!!doDownload) {
      reexecuteQuery();
      setDownloading(doDownload);
    }
  }, [doDownload]);

  if (!!downloading && result?.data?.document && !result?.fetching) {
    download(result.data.document);
    setDownloading(null);
    setDoDownload(null);
  }

  const doSelectOffer = (ev: any, offer: any) => {
    ev.preventDefault();
    let siteColors = ['#ffa68d', '#fd3a84'];

    party.element(ev.target, {
      color: siteColors,
      count: party.variation(25, 0.5),
      size: party.minmax(6, 10),
      velocity: party.minmax(-300, -600),
      angularVelocity: party.minmax(6, 9),
    });

    if (realOffer && !hasContact && !isSales) {
      completeData();
    } else {
      setSelecting(true);
      setSelectingThis(offer);
      selectOffer(offer);
    }
  };

  const downloadOffer = () => {
    if (realOffer && !hasContact && !isSales) {
      completeData();
    } else {
      setDoDownload('offer');
    }
  };

  const downloadContract = () => {
    if (!hasContact && !isSales) {
      completeData();
    } else {
      setDoDownload('contract');
    }
  };

  return isSales ? (
    <SalesView
      {...props}
      {...{
        doDownload,
        setDoDownload,
        downloading,
        setDownloading,
        isAccepted,
        isSigned,
        hasSecondaryPayment,
        doSelectOffer,
        downloadContract,
        downloadOffer,
        selectingThis,
      }}
    />
  ) : (
    <CustomerView
      {...props}
      {...{
        doDownload,
        setDoDownload,
        downloading,
        setDownloading,
        isAccepted,
        isSigned,
        hasSecondaryPayment,
        doSelectOffer,
        downloadContract,
        downloadOffer,
        selectingThis,
      }}
    />
  );
};

export default OfferView;
