import React from 'react';
import {useLoadingState} from '../../partials/LoadingProvider';
import useUser, {isLoading} from '../../../user/useUser';
import EmployeeHomeView from './employee-home-view';

const EmployeeHomeContainer: React.FC = props => {
  const user = useUser();

  useLoadingState(isLoading(user));

  return <EmployeeHomeView user={user} />;
};

export default EmployeeHomeContainer;
