import invariant from 'invariant';
import React, {useState} from 'react';
import useUser from '../../../user/useUser';
import NewsView from './news-view';
import {useMutation} from 'urql';
import {toast} from 'react-toastify';

const NewsContainer: React.FC = () => {
  const me = useUser();
  invariant(me, 'Only logged in users can see news');
  const roles = me.roles || [];

  return (
    <NewsView
      news={me.news}
      showLink={
        roles.includes('IT') ||
        roles.includes('Unternehmenskommunikation') ||
        roles.includes('Geschäftsführung') ||
        roles.includes('graph-admin') ||
        roles.includes('Head of Customer Support / GF kft') ||
        roles.includes('Head of Customer Experience & Production')
      }
    />
  );
};

export default NewsContainer;
