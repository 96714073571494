import invariant from 'invariant';
import React, {useState} from 'react';
import useUser from '../../../user/useUser';
import RequestFeedbackView from './request-feedback-view';
import {useMutation} from 'urql';
import {toast} from 'react-toastify';

const createFeedbackGQL = `
  mutation ($title: String!, $content: String!, $url: URL!, $fileName: String, $fileContent: String, $team: String) {
    createFeedback(title: $title, content: $content, url: $url, fileName: $fileName, fileContent: $fileContent, team: $team)
    {
      id
      articles
      {
        id
        title
        summary
        content
      }
    }
  }
`;

const activateFeedbackGQL = `
  mutation ($id: ID!) {
    activateFeedback(id: $id)
  }
`;

const deactivateFeedbackGQL = `
  mutation ($id: ID!) {
    deactivateFeedback(id: $id)
  }
`;

const gglupdateUserSettings = `
  mutation ($value: JSON!) {
    updateUserSettings(value: $value)
  }
`;

const getBase64 = async (file: File): Promise<string> => {
  const reader: FileReader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      if (reader.result != null) {
        resolve((reader.result as string).split(',')[1]);
      }
    };

    reader.onerror = reject;
  });
};

const RequestFeedbackContainer: React.FC = () => {
  const me = useUser();
  invariant(me, 'Only logged in users can send feedback');

  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [body, setBody] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');
  const [team, setTeam] = useState('web');
  const [articles, setArticles] = useState([]);
  const [id, setId] = useState(null);
  const [sendFeedbackResult, sendFeedback] = useMutation(createFeedbackGQL);
  const [activateFeedbackResult, activateFeedback] = useMutation(
    activateFeedbackGQL,
  );

  const isCustomer = me?.type === 'customer';

  const [deactivateFeedbackResult, deactivateFeedback] = useMutation(
    deactivateFeedbackGQL,
  );
  const [gotFeedback, setGotFeedback] = useState<any>(null);
  const [updateUserSettingsResult, updateUserSettings] = useMutation(
    gglupdateUserSettings,
  );

  const seen = !!me?.settings?.tutorials?.['feedback-form'];

  const onFile = async (ev: any) => {
    if (ev.target.files[0]) {
      const b64 = await getBase64(ev.target.files[0]);
      setFileContent(b64);
      setFileName(ev.target.files[0].name);
    }
  };

  const doMarkTutorialSeen = () => {
    if (seen) return;

    const settings = me.settings || {};
    settings.tutorials = settings.tutorials || {};
    settings.tutorials['feedback-form'] = true;

    updateUserSettings({value: settings}).then(result => {});
  };

  const onCancel = () => {
    if (id) {
      deactivateFeedback({
        id: id,
      });
    }

    setFeedbackVisible(false);
    setArticles(old => []);
    setId(null);
    setDescription('');
    setBody('');
  };

  const doActivateFeedback = async (id: string | null) => {
    if (id != null) {
      toast('Vielen Dank! Wir melden uns bei Ihnen per E-Mail.', {
        autoClose: 2000,
      });

      setFeedbackVisible(false);
      setArticles(old => []);
      setId(null);
      await activateFeedback({
        id: id,
      });
    }
  };

  const handleSubmitClick = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (!body || !description) return;

    try {
      const result = await sendFeedback({
        title: description,
        content: body,
        url: document.location + '',
        fileContent: fileContent,
        fileName: fileName,
        team: team,
      });

      const item = result.data?.createFeedback;
      setGotFeedback(item);

      if (item) {
        if (item.articles?.length && item.id) {
          setId(item.id);
          setArticles(old => item.articles);
        } else {
          await doActivateFeedback(item.id);
        }
      }
    } catch (e) {
      console.log(e);
    }

    setDescription('');
    setBody('');
  };

  return (
    <RequestFeedbackView
      id={id}
      articles={articles}
      activateFeedback={doActivateFeedback}
      onFeedbackClick={() => setFeedbackVisible(true)}
      onSubmitClick={handleSubmitClick}
      onBodyChange={ev => setBody(ev.target.value)}
      onDescriptionChange={ev => setDescription(ev.target.value)}
      visible={feedbackVisible}
      body={body}
      description={description}
      onFileChange={ev => onFile(ev)}
      onTutorialSeen={() => doMarkTutorialSeen()}
      tutorialVisible={me && !seen}
      onCancelClick={() => onCancel()}
      team={team}
      setTeam={setTeam}
      isCustomer={isCustomer}
    />
  );
};

export default RequestFeedbackContainer;
