import React, {useState} from 'react';
import {useLoadingState} from '../../partials/LoadingProvider';
import NotFound from '../NotFound';
import ActivateAccountView from './activate-account-view';
import {ActivateAccountFormValues, ActivateAccountProps} from './types';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ActivateAccountContainer: React.FC<ActivateAccountProps> = props => {
  const activationKey = props.match?.params?.activationKey;
  const [activationDone, setActivationDone] = useState(false);
  const [landing, setLanding] = useState('');

  useLoadingState();

  //
  // form validation rules
  //
  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Bitte füllen Sie dieses Feld aus.'),
  });

  //
  // Finalize the form
  //
  async function handleSubmit(
    values: ActivateAccountFormValues,
    actions: FormikHelpers<ActivateAccountFormValues>,
  ) {
    try {
      const ret = await axios.post(process.env.REACT_APP_SERVER + '/api/activate', {
        key: activationKey,
        password: values.password,
      });

      setLanding(ret?.data?.data?.activateCustomerAccount || '');
      setActivationDone(true);
    } catch (e) {
      console.log(e);
      actions.setFieldError(
        'password',
        'Es gab einen Fehler bei der Aktivierung. Bitte versuch es später noch einmal.',
      );
    }
  }

  return activationKey ? (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <ActivateAccountView
          {...formikProps}
          landing={landing}
          activationDone={activationDone}
        />
      )}
    </Formik>
  ) : (
    <NotFound />
  );
};

export default ActivateAccountContainer;
