import React from 'react';
import FullCalendar, {CustomButtonInput, EventApi} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import deLocale from '@fullcalendar/core/locales/de';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import 'tippy.js/dist/tippy.css';
import './index.css';
import {TimetableViewProps} from './types';

const TimetableView: React.FC<TimetableViewProps> = props => {
  const {isReady, groups, events, timetableForComponent, onGroupClick} = props;

  // https://fullcalendar.io/docs/react
  const openPodio = (item: EventApi) => {
    if (item._def.extendedProps.link) {
      window.open(item._def.extendedProps.link, '_blank');
    }
  };

  //
  // this is UI related code, so we should keep in the view
  //
  const customButtons: {[name: string]: CustomButtonInput} = {};
  const customPlacement: string[] = [];
  Object.keys(groups).forEach(group => {
    customPlacement.push('group' + group);
    customButtons['group' + group] = {
      text: 'Gruppe ' + group,
      click: function () {
        onGroupClick(group);
      },
    };
  });

  return (
    <div className="Timetable">
      <main className="container-fluid pt-5 pb-5" role="main">
        <div className="container">
          <div className="">
            <h1 className="mb-1">Stundenplan</h1>
            <p className="lead">{timetableForComponent?.name}</p>
          </div>
          <hr className="my-5" />
          {isReady && (
            <FullCalendar
              eventContent={eventInfo => {
                return (
                  <Tippy content={eventInfo.event.title}>
                    <div
                      className={classNames({
                        'fc-event-main-frame': true,
                        'timetable-special-finish':
                          eventInfo.event._def.extendedProps.lastWeek,
                        'timetable-special-start':
                          eventInfo.event._def.extendedProps.firstWeek,
                        'timetable-special-break':
                          eventInfo.event._def.extendedProps.break,
                      })}>
                      <div
                        onClick={ev => openPodio(eventInfo.event)}
                        className="fc-event-time">
                        {eventInfo.timeText}
                      </div>
                      <div className="fc-event-title-container">
                        <div className="fc-event-title fc-sticky">
                          {eventInfo.event.title}
                        </div>
                      </div>
                    </div>
                  </Tippy>
                );
              }}
              locales={[deLocale]}
              plugins={[dayGridPlugin, timeGridPlugin]}
              slotMinTime="09:00:00"
              slotMaxTime="17:00:00"
              locale="de"
              initialView="timeGridWeek"
              expandRows={true}
              weekends={false}
              slotEventOverlap={false}
              nowIndicator={true}
              events={events}
              eventMinHeight={50}
              aspectRatio={0.5}
              allDaySlot={false}
              headerToolbar={{
                left: 'title',
                center: customPlacement.join(' '),
                right: 'today prev,next',
              }}
              customButtons={customButtons}
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default TimetableView;
