import {ErrorMessage, Field, Form} from 'formik';
import React, {useState} from 'react';
import {ViewProps} from './types';
import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';
import View from './view';

import cn from 'classnames';

const uploadDocument = `
  mutation ($id: ID!, $name: String!, $type: String!, $content: String!) {
    uploadPotentialDocument (id: $id, name: $name, type: $type, base64Content: $content)
  }
`;

const BGSViewContainer: React.FC<ViewProps> = props => {
  const {potential, isSales, setWorking, onSetStartDate} = props;
  const [uploadResult, doUpload] = useMutation(uploadDocument);

  const getBase64 = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    setWorking(true);

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        //@ts-ignore
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
    }).finally(() => setWorking(false));
  };

  //@ts-ignore
  const handleUpload = async (type, ev) => {
    ev.preventDefault();

    //@ts-ignore
    const file = ev.target.files[0];
    doUpload({
      id: potential.id,
      type: type,
      name: file.name,
      content: await getBase64(file),
    }).then(result => {
      //@ts-ignore
      // TODO
    });
  };

  return (
    <View
      onSetStartDate={onSetStartDate}
      setWorking={setWorking}
      isSales={isSales}
      onUpload={handleUpload}
      potential={potential}
    />
  );
};

export default BGSViewContainer;
