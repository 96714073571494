import React from 'react';
import LoginView from './login-view';
import * as Yup from 'yup';
import {LoginFormValues} from './types';
import {Formik, FormikHelpers} from 'formik';
import createLoginUrl from '../utils/createLoginUrl';

const LoginContainer: React.FC = () => {
  //
  // form validation rules
  //
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
      .required('Bitte füllen Sie dieses Feld aus'),
  });

  //
  // Finalize the form
  //
  async function handleSubmit(
    values: LoginFormValues,
    actions: FormikHelpers<LoginFormValues>,
  ) {
    document.location.assign(
      createLoginUrl({
        realm: 'customer',
        login_hint: values.email,
      }),
    );
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <LoginView
          {...formikProps}
          passwordResetUrl={createLoginUrl({
            realm: 'password-reset',
          })}
          loginEmployeeUrl={createLoginUrl({
            realm: 'employee',
          })}
        />
      )}
    </Formik>
  );
};

export default LoginContainer;
