export default function createLoginUrl(params: Record<string, string>): string {
  return (
    (process.env.REACT_APP_CUSTOMER_LOGIN_URL ||
      'https://graph.karrieretutor.de/login') +
    '?' +
    new URLSearchParams({
      redirect_url: document.location.href, //by default, we come back to the current page (unless otherwise specified)
      ...params,
    }).toString()
  );
}
