import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {ViewProps} from './types';
import cn from 'classnames';
//@ts-ignore
import party from 'party-js';

const images = [
  'https://www.karrieretutor.de/wp-content/uploads/2020/12/Lehrgaenge-Kategorie-Marketing-Vertrieb-1.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2020/12/Lehrgaenge-Kategorie-Marketing-Vertrieb-3.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2020/12/Lehrgaenge-Kategorie-Marketing-Vertrieb-9.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2020/12/Lehrgaenge-Kategorie-IT-3.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2021/02/boxbild-ausbildung-der-ausbilder-ihk.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2021/02/boxbild-personalfachkaufmann.jpg',
  'https://www.karrieretutor.de/wp-content/uploads/2020/12/Lehrgaenge-Kategorie-Verwaltung-4.jpg',
];

const randomImage = (what: any) => {
  const randImage = () =>
    images[
      (Math.round(Math.random() * 10 + images.length) % images.length) - 1
    ];
  if (!what) return randImage();

  return images[(what.id * 1) % images.length] || randImage();
};

const View: React.FC<ViewProps> = props => {
  const {
    payment,
    offer,
    selectOffer,
    signOffer,
    ableToAccept,
    realOffer,
    ableToBuy,
    hasContact,
    completeData,
    isSales,
    reloadData,
    start,
    selecting,
    setSelecting,
    isAccepted,
    isSigned,
    hasSecondaryPayment,
    doSelectOffer,
    downloadOffer,
    downloadContract,
    selectingThis,
  } = props;

  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [contractAccepted, setContractAccepted] = useState(false);
  const [signing, setSigning] = useState(false);

  const doSignOffer = (ev: any, offer: any) => {
    if (isSales) return;
    if (!ableToBuy || !privacyAccepted || !contractAccepted) return;

    ev.preventDefault();
    let siteColors = ['#ffa68d', '#fd3a84'];

    party.element(ev.target, {
      color: siteColors,
      count: party.variation(25, 0.5),
      size: party.minmax(6, 10),
      velocity: party.minmax(-300, -600),
      angularVelocity: party.minmax(6, 9),
    });

    if (realOffer && !hasContact && !isSales) {
      completeData();
    } else {
      setSigning(true);
      signOffer(offer, privacyAccepted, contractAccepted);
    }
  };

  return (
    <div className="card kt-box">
      <img
        className="card-img-top"
        hidden={false}
        style={{
          borderRadius: '30px 30px 0px 0px',
          position: ableToBuy ? 'absolute' : 'static',
          zIndex: 0,
          opacity: ableToBuy ? 0.15 : 1,
        }}
        src={randomImage(offer)}
        alt={offer?.package?.title}
      />
      <div className="card-body" style={{zIndex: 1}}>
        <h5 className="card-title">{offer?.package?.title}</h5>

        <p>
          <i className="fa fa-info-circle"></i>{' '}
          {offer.package?.url ? (
            <a href={offer.package.url} target="_blank">
              Informationen
            </a>
          ) : null}
        </p>
        <p hidden={isSigned}>
          <i className="fa fa-clock"></i> {offer?.package?.duration} Wochen
        </p>
        <div hidden={isSigned}>
          {hasSecondaryPayment ? (
            <>
              <p style={{fontWeight: 'bold'}}>
                <i className="fa fa-money-bill-wave"></i>{' '}
                {payment?.primary?.amount?.toLocaleString('de-DE', {
                  currency: 'EUR',
                  style: 'currency',
                })}{' '}
                ({payment?.primary?.type})
              </p>
              <p style={{fontWeight: 'bold'}}>
                <i className="fa fa-money-bill-wave"></i>{' '}
                {payment?.secondary?.amount?.toLocaleString('de-DE', {
                  currency: 'EUR',
                  style: 'currency',
                })}{' '}
                ({payment?.secondary?.type})
              </p>
            </>
          ) : (
            <p style={{fontWeight: 'bold'}}>
              <i className="fa fa-money-bill-wave"></i>{' '}
              {offer.price?.toLocaleString('de-DE', {
                currency: 'EUR',
                style: 'currency',
              })}{' '}
            </p>
          )}
        </div>
        <p hidden={isSigned}>
          <a
            href="#"
            onClick={ev => {
              ev.preventDefault();
              downloadOffer();
            }}>
            <i className="fa fa-download"></i>{' '}
            {realOffer ? 'Angebot' : 'Bildungsempfehlung'}
          </a>
        </p>

        <div hidden={!isAccepted}>
          <p hidden={true}>
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                //setDoDownload('qualificationplan');
              }}>
              <i className="fa fa-download"></i> Qualifikationsplan
            </a>
          </p>
          <p hidden={true}>
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                //setDoDownload('courseplan');
              }}>
              <i className="fa fa-download"></i> Kursplan
            </a>
          </p>
          <p>
            <a
              href="#"
              onClick={ev => {
                ev.preventDefault();
                downloadContract();
              }}>
              <i className="fa fa-download"></i>{' '}
              {isSigned ? 'Abgeschlossener Vertrag' : 'Vertrag'}
            </a>
          </p>
          <p>
            <a href="/assets/datenschutz-teilnehmer.pdf" target="_blank">
              <i className="fa fa-download"></i> Datenschutzhinweise
            </a>
          </p>
        </div>
        <div hidden={isSigned || !(isAccepted && ableToBuy)}>
          <br />
          <div style={{paddingLeft: '20px'}}>
            <div
              className="form-check"
              style={{float: 'left', margin: 0, padding: 0}}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={ev => setPrivacyAccepted(ev.target.checked)}
                id="privacyAccept"
              />
              <label
                htmlFor="privacyAccept"
                className="form-check-label"></label>
            </div>
            <p>
              Ich habe die{' '}
              <a href="/assets/datenschutz-teilnehmer.pdf" target="_blank">
                <i className="fa fa-download"></i> Datenschutzhinweise
              </a>{' '}
              gelesen, verstanden und akzeptiere diese.
            </p>
          </div>
          <div style={{paddingLeft: '20px'}}>
            <div
              className="form-check"
              style={{float: 'left', margin: 0, padding: 0}}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={ev => setContractAccepted(ev.target.checked)}
                id="contractAccept"
              />
              <label
                htmlFor="contractAccept"
                className="form-check-label"></label>
            </div>
            <p>
              Ich akzeptiere die{' '}
              <a
                href="#"
                onClick={ev => {
                  ev.preventDefault();
                  downloadContract();
                }}>
                <i className="fa fa-download"></i> Vertragsbedingungen
              </a>
              .
            </p>
          </div>
          <p style={{textAlign: 'center', margin: 0}} hidden={!signing}>
            <img src="/images/loading.svg" style={{width: '1.5rem'}} /> Wir
            bereiten Ihren Vertrag zur Unterschrift vor ...
          </p>
          <p style={{textAlign: 'center', margin: 0}} hidden={signing}>
            <button
              onClick={ev => {
                doSignOffer(ev, offer);
              }}
              className="kt-btn kt-primary">
              Vertrag abschließen
            </button>
          </p>
        </div>
      </div>
      <div className="card-footer text-muted">
        <span hidden={!isAccepted || isSigned}>Angebot ausgewählt</span>
        <span hidden={!isSigned}>
          Ihr Starttermin: {new Date(start).toLocaleDateString()}
        </span>
        <span
          hidden={
            selectingThis !== offer ||
            !selecting ||
            isAccepted ||
            isSigned ||
            signing
          }>
          <img src="/images/loading.svg" style={{width: '1.5rem'}} /> Wir
          bearbeiten Ihre Anfrage ...
        </span>
        <p
          hidden={!(ableToAccept && offer.status === 'open') || selecting}
          style={{textAlign: 'center', margin: 0}}>
          <button
            onClick={ev => {
              doSelectOffer(ev, offer);
            }}
            className="kt-btn kt-primary">
            Auswählen
          </button>
        </p>
      </div>
    </div>
  );
};

export default View;
