import React from 'react';
import gql from 'graphql-tag';
import {useQuery} from 'urql';
import {useLoadingState} from '../../partials/LoadingProvider';
import InvitedHomeView from './invited-home-view';
import useUser, {isLoading} from '../../../user/useUser';

const query = gql`
  {
    me {
      transactions {
        id
      }
    }
  }
`;

const InvitedHomeContainer: React.FC = props => {
  const user = useUser();

  const [{data, fetching, error}, reexecuteQuery] = useQuery({
    query: query,
    variables: {},
  });

  useLoadingState(isLoading(user) || fetching);

  return <InvitedHomeView />;
};

export default InvitedHomeContainer;
