import {ErrorMessage, Field, Form} from 'formik';
import React, {useState} from 'react';
import {VariantViewProps, Package} from './types';
import gql from 'graphql-tag';
import {useQuery} from 'urql';
import SearchView from './search-view';
import {Sheet} from './types';
import getSelectValues from './utils/getSelectValues';

import cn from 'classnames';

const query = gql`
  {
    taxonomies {
      name
    }
    usedProductComponents
  }
`;

const searchQuery = gql`
  query(
    $keywords: [String]
    $components: [String]
    $months: Int
    $sheets: [String]
    $type: PackageType
  ) {
    findPackages(
      keywords: $keywords
      components: $components
      months: $months
      sheets: $sheets
      type: $type
    ) {
      id
      name
      duration
      months
      components
      taxonomy {
        name
      }
      url
      link
      matchedComponents
      score
      price
      sheets {
        id
        name
      }
    }
  }
`;

const VariantView: React.FC<VariantViewProps> = props => {
  const {onSelectPackages, isSales, selection} = props;
  const [sheet, setSheet] = useState<Sheet | null>(null);
  const [keywords, setKeywords] = useState([]);
  const [packages, setPackages] = useState<Array<Package>>([]);
  const [comps, setComponents] = useState<string[]>([]);
  const [months, setMonths] = useState(0);

  const [{data: components, fetching: fetchingComponents}] = useQuery({
    query: query,
    variables: {},
    pause: !isSales,
  });

  const [{data: resultsData, fetching: fetchingResults, error}] = useQuery({
    query: searchQuery,
    variables: {
      months: months,
      keywords: keywords,
      sheets: sheet ? [sheet.id] : null,
      components: comps,
      type: selection,
    },
    pause: !keywords.length,
  });

  return (
    <div hidden={!!packages.length}>
      <SearchView
        selection={selection}
        components={components}
        isSales={isSales}
        results={resultsData?.findPackages}
        selectedSheet={sheet}
        onCloseSelectedSheetClick={ev => {
          ev.preventDefault();
          setSheet(null);
        }}
        onSheetClick={(ev, sheet) => {
          ev.preventDefault();
          setSheet(sheet);
        }}
        onProductComponentsChange={ev => {
          setComponents(getSelectValues(ev.target));
        }}
        onKeywordsKeyUp={ev =>
          //@ts-ignore - there is something wrong with the typescript definition, and it does not see the target value, so ignore it
          setKeywords(ev.target.value.split(/ +/).filter(val => !!val))
        }
        onMonthsChange={ev => setMonths(parseInt(ev.target.value))}
        onSelectPackages={items => {
          setPackages(items);
          onSelectPackages(items);
        }}
      />
    </div>
  );
};

export default VariantView;
