import React from 'react';
import {SearchViewProps} from './types';

import './index.css';

const SearchView: React.FC<SearchViewProps> = props => {
  const {
    onKeywordsKeyUp,
    onMonthsChange,
    onProductComponentsChange,
    usedProductComponents,
    results,
    selectedSheet,
    onSheetClick,
    onCloseSelectedSheetClick,
  } = props;

  return (
    <div className="Search">
      <main className="container-fluid pt-5 pb-5" role="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Produkte</h1>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <div className="kt-form">
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-book input-prefix"></i>
                  <input
                    type="search"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    onKeyUp={onKeywordsKeyUp}
                  />
                  <label>Name/Keywords</label>
                </div>
              </div>

              <div className="kt-form">
                <div className="md-form input-with-pre-icon pre-icon md-outline">
                  <i className="fas fa-calendar input-prefix"></i>
                  <input
                    type="number"
                    onChange={onMonthsChange}
                    className="wpcf7-form-control wpcf7-text form-control"
                  />
                  <label>Dauer/Monate</label>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="kt-form kt-select">
                <select
                  className="wpcf7-form-control wpcf7-text form-control"
                  multiple
                  onChange={onProductComponentsChange}>
                  {usedProductComponents?.map(com => {
                    return (
                      <option key={com} value={com}>
                        {com}
                      </option>
                    );
                  })}
                </select>
                <label>
                  Komponenten auswählen (Mehrfauswahlt mit <code>Strg</code>{' '}
                  oder <code>Cmd</code>):
                </label>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="kt-form">
                <strong>Maßnahmenbogen</strong>
                {selectedSheet ? (
                  <div>
                    {selectedSheet.name}{' '}
                    <a href="#" onClick={onCloseSelectedSheetClick}>
                      x
                    </a>
                  </div>
                ) : (
                  <div>Kein Maßnahmenbogen ausgewählt</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {results?.map(product => {
                return (
                  <div
                    key={product.id}
                    style={{
                      justifyContent: 'space-around',
                      display: 'flex-inline',
                      padding: '12px',
                      marginTop: '1em',
                      border: '1px solid #ccc',
                    }}>
                    <div>
                      <a href={product.url} target="_blank">
                        {product.name}
                      </a>

                      {product.ibb && (
                        <>
                          &nbsp;<span className="badge badge-info">IBB</span>
                        </>
                      )}
                      {product.noSell && (
                        <>
                          &nbsp;
                          <span className="badge badge-warning">
                            nicht verkaufbar
                          </span>
                        </>
                      )}
                    </div>
                    <div>
                      {product.months} Monate ({product.duration} Wochen){' '}
                      {product.price && (
                        <div style={{float: 'right', color: '#FF7C09'}}>
                          {product.price} €
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '6px',
                        marginTop: '1em',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}>
                      <div>
                        <strong>Enthaltene Komponenten</strong>
                        {product.components?.map(com => {
                          const color = product.matchedComponents?.some(
                            c => c === com,
                          )
                            ? 'green'
                            : '';
                          return (
                            <div
                              key={com}
                              style={{paddingLeft: '6px', color: color}}>
                              {com}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <strong>Maßnahmenbogen</strong>
                        {product.sheets?.map(sheet => {
                          return (
                            <div key={sheet.id} style={{paddingLeft: '6px'}}>
                              <a
                                href="#"
                                style={{fontSize: '1rem'}}
                                onClick={ev => onSheetClick(ev, sheet)}>
                                {sheet.name}
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>

      {/* <main className="fullwidth pt-5" role="main">
        <div className="container kt-form">
          <h1>Produkte</h1>
          <div>
            <div>
              <div>
                <label>
                  Name/Keywords:{' '}
                  <input
                    type="search"
                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                    placeholder="Name und Keywords durchsuchen"
                    onKeyUp={onKeywordsKeyUp}
                  />
                </label>
              </div>
              <label>
                Dauer:{' '}
                <input
                  type="number"
                  onChange={onMonthsChange}
                  placeholder="Monate"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                />
              </label>
            </div>
            <div>
              <div>
                <strong>Komponenten</strong> auswählen (Mehrfauswahlt mit{' '}
                <code>Strg</code> oder <code>Cmd</code>):
              </div>
              <select
                style={{height: '200px', overflow: 'show'}}
                multiple
                onChange={onProductComponentsChange}>
                {usedProductComponents?.map(com => {
                  return (
                    <option key={com} value={com}>
                      {com}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <strong>Maßnahmenbogen</strong>
              {selectedSheet ? (
                <div>
                  {selectedSheet.name}{' '}
                  <a href="#" onClick={onCloseSelectedSheetClick}>
                    x
                  </a>
                </div>
              ) : (
                <div>Kein Maßnahmenbogen ausgewählt</div>
              )}
            </div>
          </div>
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)'}}>
            {results?.map(product => {
              return (
                <div
                  key={product.id}
                  style={{
                    justifyContent: 'space-around',
                    display: 'flex-inline',
                    padding: '12px',
                    marginTop: '1em',
                    border: '1px solid #ccc',
                  }}>
                  <div>
                    <a href={product.url} target="_blank">
                      {product.name}
                    </a>
                  </div>
                  <div>
                    {product.months} Monate ({product.duration} Wochen){' '}
                    {product.price && (
                      <div style={{float: 'right', color: '#FF7C09'}}>
                        {product.price} €
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '6px',
                      marginTop: '1em',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    }}>
                    <div>
                      <strong>Enthaltene Komponenten</strong>
                      {product.components?.map(com => {
                        const color = product.matchedComponents?.some(
                          c => c === com,
                        )
                          ? 'green'
                          : '';
                        return (
                          <div
                            key={com}
                            style={{paddingLeft: '6px', color: color}}>
                            {com}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <strong>Maßnahmenbogen</strong>
                      {product.sheets?.map(sheet => {
                        return (
                          <div key={sheet.id} style={{paddingLeft: '6px'}}>
                            <a
                              href="#"
                              style={{fontSize: '1rem'}}
                              onClick={ev => onSheetClick(ev, sheet)}>
                              {sheet.name}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> 
      </main> */}
    </div>
  );
};

export default SearchView;
