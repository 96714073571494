import React, {useState} from 'react';
import {SearchViewProps, Package} from './types';

const status = {
  open: 'Offen',
  accepted: 'Ausgewählt',
  rejected: 'Abgelehnt',
  maybe: 'Vorgemerkt',
};

const SearchView: React.FC<SearchViewProps> = props => {
  const {
    onKeywordsKeyUp,
    onMonthsChange,
    onSelectPackages,
    results,
    isSales,
    components,
    selectedSheet,
    onCloseSelectedSheetClick,
    onProductComponentsChange,
    onSheetClick,
    selection,
  } = props;

  const [comps, setComponents] = useState<string[]>([]);

  const [packages, setPackages] = useState<Array<Package>>([]);
  return (
    <div className="Search">
      <div className="container">
        <div className="row">
          <img src="/images/search-header.png" alt="" style={{opacity: 0.5}} />
        </div>
        <div className="row mb-4 jumbotron" style={{minHeight: '250px'}}>
          <div className="col-2 col-md-4">
            <div className="kt-form" hidden={!isSales && packages.length > 2}>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-book input-prefix"></i>
                <input
                  type="search"
                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                  onKeyUp={onKeywordsKeyUp}
                />
                <label>Name/Keywords</label>
              </div>
            </div>

            <div className="kt-form" hidden={!isSales && packages.length > 2}>
              <div className="md-form input-with-pre-icon pre-icon md-outline">
                <i className="fas fa-calendar input-prefix"></i>
                <input
                  type="number"
                  onChange={onMonthsChange}
                  className="wpcf7-form-control wpcf7-text form-control"
                />
                <label>Dauer/Monate</label>
              </div>
            </div>

            <div className="kt-form" hidden={!isSales}>
              <div className="kt-form kt-select">
                <select
                  className="wpcf7-form-control wpcf7-text form-control"
                  multiple
                  onChange={onProductComponentsChange}>
                  {components?.usedProductComponents?.map((com: any) => {
                    return (
                      <option key={com} value={com}>
                        {com}
                      </option>
                    );
                  })}
                </select>
                <label>
                  Lehrgänge auswählen (Mehrfauswahlt mit <code>Strg</code> oder{' '}
                  <code>Cmd</code>):
                </label>
              </div>
            </div>

            <div className="kt-form" hidden={!isSales}>
              <div className="kt-form">
                <strong>Maßnahmenzertifikat</strong>
                {selectedSheet ? (
                  <div>
                    {selectedSheet.name}{' '}
                    <a href="#" onClick={onCloseSelectedSheetClick}>
                      x
                    </a>
                  </div>
                ) : (
                  <div>Kein Maßnahmenzertifikat ausgewählt</div>
                )}
              </div>
            </div>
            <div className="kt-form">
              <div hidden={!packages.length}>
                <a
                  href="#"
                  className="kt-btn kt-primary"
                  style={{width: '100%'}}
                  onClick={ev => {
                    ev.preventDefault();
                    onSelectPackages(packages);
                  }}>
                  Fertig!
                </a>
              </div>
            </div>
          </div>
          {!packages.length && (
            <p>
              Bitte wählen Sie bis zu drei Weiterbildungen, für die Sich
              interessieren:
            </p>
          )}
          <div
            className="col-12 col-md-8"
            style={{
              display: 'grid',
              gridTemplateColumns: isSales
                ? 'repeat(1, 1fr)'
                : 'repeat(3, 1fr)',
              gridGap: '2rem',
              gridAutoRows: 'minmax(100px, auto)',
            }}>
            {packages.map(p =>
              isSales ? (
                <>
                  <div
                    key={p.id}
                    style={{
                      justifyContent: 'space-around',
                      display: 'flex-inline',
                      padding: '12px',
                      marginTop: '1em',
                      border: '1px solid #ccc',
                    }}>
                    <div>
                      <div style={{float: 'right'}}>
                        {p.maybe ? 'Vorgemerkt' : ''}
                      </div>
                      <a href={p.link} target="_blank">
                        {p.name}
                      </a>

                      {p.ibb && (
                        <>
                          &nbsp;<span className="badge badge-info">IBB</span>
                        </>
                      )}
                      {p.noSell && (
                        <>
                          &nbsp;
                          <span className="badge badge-warning">
                            nicht verkaufbar
                          </span>
                        </>
                      )}
                    </div>
                    <div>
                      {p.months} Monate ({p.duration} Wochen){' '}
                      {p.price && (
                        <div style={{float: 'right'}}>
                          <p style={{color: '#FF7C09'}}>
                            {' '}
                            {p.price?.toLocaleString('de-DE', {
                              currency: 'EUR',
                              style: 'currency',
                            })}{' '}
                          </p>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '6px',
                        marginTop: '1em',
                        display: 'grid',
                        width: '100%',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}>
                      <div>
                        <strong>Enthaltene Lehrgänge</strong>
                        {p.components?.map(com => {
                          return (
                            <div key={com} style={{paddingLeft: '6px'}}>
                              {com}
                            </div>
                          );
                        })}
                      </div>

                      <div>
                        <strong>Maßnahmenzertifikat</strong>
                        {p.sheets?.map(sheet => {
                          return (
                            sheet &&
                            sheet.id && (
                              <div key={sheet.id} style={{paddingLeft: '6px'}}>
                                {sheet.name}
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <a
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        setPackages(prev => [
                          ...prev.filter(item => item.id !== p.id),
                        ]);
                      }}
                      className="kt-btn kt-secondary"
                      style={{padding: '10px 20px', marginRight: '1rem'}}>
                      Entfernen!
                    </a>
                  </div>
                </>
              ) : (
                <div className="card" key={p.id}>
                  <img className="card-img-top" src="..." alt="" />
                  <div className="card-body">
                    <h5 className="card-title" style={{fontSize: '1rem'}}>
                      {p.name}
                    </h5>
                  </div>
                  <div className="card-footer text-muted">
                    <a
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        setPackages(prev => [
                          ...prev.filter(item => item.id !== p.id),
                        ]);
                      }}
                      className="kt-btn kt-secondary"
                      style={{padding: '10px 20px', marginRight: '1rem'}}>
                      Entfernen!
                    </a>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className="row">
          <div
            hidden={false}
            style={{
              display: 'grid',
              gridTemplateColumns: isSales
                ? 'repeat(1, 1fr)'
                : 'repeat(3, 1fr)',
              gridGap: '2rem',
              gridAutoRows: 'minmax(100px, auto)',
              width: '100%',
            }}>
            {results?.map(p => {
              return isSales ? (
                <>
                  <div
                    key={p.id}
                    style={{
                      justifyContent: 'space-around',
                      display: 'flex-inline',
                      padding: '12px',
                      marginTop: '1em',
                      border: '1px solid #ccc',
                      width: '100%',
                    }}>
                    <div>
                      <div style={{float: 'right'}}>
                        {p.maybe ? 'Vorgemerkt' : ''}
                      </div>
                      <a href={p.link} target="_blank">
                        {p.name}
                      </a>

                      {p.ibb && (
                        <>
                          &nbsp;<span className="badge badge-info">IBB</span>
                        </>
                      )}
                      {p.noSell && (
                        <>
                          &nbsp;
                          <span className="badge badge-warning">
                            nicht verkaufbar
                          </span>
                        </>
                      )}
                    </div>
                    <div>
                      {p.months} Monate ({p.duration} Wochen){' '}
                      {p.price && (
                        <div style={{float: 'right'}}>
                          <p style={{color: '#FF7C09'}}>
                            {' '}
                            {p.price?.toLocaleString('de-DE', {
                              currency: 'EUR',
                              style: 'currency',
                            })}{' '}
                          </p>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '6px',
                        marginTop: '1em',
                        display: 'grid',
                        width: '100%',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                      }}>
                      <div>
                        <strong>Enthaltene Lehrgänge</strong>
                        {p.components?.map(com => {
                          const color = p.matchedComponents?.some(
                            c => c === com,
                          )
                            ? 'green'
                            : '';
                          return (
                            <div
                              key={com}
                              style={{paddingLeft: '6px', color: color}}>
                              {com}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <strong>Maßnahmenzertifikat</strong>
                        {p.sheets?.map(sheet => {
                          return (
                            sheet &&
                            sheet.id && (
                              <div key={sheet.id} style={{paddingLeft: '6px'}}>
                                <a
                                  href="#"
                                  style={{fontSize: '1rem'}}
                                  onClick={ev => onSheetClick(ev, sheet)}>
                                  {sheet.name}
                                </a>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                    <br />
                    <a
                      hidden={
                        !isSales ||
                        packages.filter(pack => pack.id === p.id).length > 0
                      }
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        setPackages(prev => [
                          ...prev.filter(item => item.id !== p.id),
                          p,
                        ]);
                      }}
                      className="kt-btn kt-primary"
                      style={{padding: '10px 20px', marginRight: '1rem'}}>
                      Anbieten!
                    </a>
                    <a
                      hidden={
                        !isSales ||
                        packages.filter(pack => pack.id === p.id).length > 0
                      }
                      href="#"
                      onClick={ev => {
                        ev.preventDefault();
                        setPackages(prev => [
                          ...prev.filter(item => item.id !== p.id),
                          {...p, maybe: true},
                        ]);
                      }}
                      className="kt-btn kt-secondary"
                      style={{padding: '10px 20px', marginRight: '1rem'}}>
                      Vormerken!
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="card" key={p.id}>
                    <img className="card-img-top" src="..." alt="" />
                    <div className="card-body">
                      <h5 className="card-title">{p.name}</h5>
                      <p className="card-text">
                        {p.months} Monate ({p.duration} Wochen){' '}
                      </p>
                      {selection === 'Selbstzahler' && (
                        <p style={{color: '#FF7C09'}}>
                          {' '}
                          {p.price?.toLocaleString('de-DE', {
                            currency: 'EUR',
                            style: 'currency',
                          })}{' '}
                        </p>
                      )}
                    </div>
                    <div className="card-footer text-muted">
                      <a
                        hidden={
                          isSales ||
                          packages.length > 2 ||
                          packages.filter(pack => pack.id === p.id).length > 0
                        }
                        href="#"
                        onClick={ev => {
                          ev.preventDefault();
                          setPackages(prev => [
                            ...prev.filter(item => item.id !== p.id),
                            p,
                          ]);
                        }}
                        className="kt-btn kt-primary"
                        style={{padding: '10px 20px', marginRight: '1rem'}}>
                        Auswählen!
                      </a>
                      <a href={isSales ? p.link : p.url} target="_blank">
                        Informationen
                      </a>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchView;
