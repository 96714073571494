import {ErrorMessage, Field, Form} from 'formik';
import React, {useState, useEffect} from 'react';
import {ViewProps, status} from './types';
import cn from 'classnames';
//@ts-ignore
import party from 'party-js';

import gql from 'graphql-tag';
import {useQuery, useMutation} from 'urql';

const removeOfferMutation = gql`
  mutation($id: ID!) {
    removeOffer(id: $id)
  }
`;

const View: React.FC<ViewProps> = props => {
  const {
    payment,
    offer,
    selectOffer,
    signOffer,
    ableToAccept,
    realOffer,
    ableToBuy,
    hasContact,
    completeData,
    isSales,
    reloadData,
    start,
    selecting,
    setSelecting,
    isAccepted,
    isSigned,
    hasSecondaryPayment,
    downloadOffer,
    downloadContract,
  } = props;

  const [removeOfferResult, removeOffer] = useMutation(removeOfferMutation);

  const doRemoveOffer = async (ev: any, offer: any) => {
    ev.preventDefault();

    try {
      const result = await removeOffer({id: offer.id});
      if (result.error) {
        throw new Error(result.error.message);
      }

      await reloadData();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        style={{
          justifyContent: 'space-around',
          display: 'flex-inline',
          padding: '12px',
          marginTop: '1em',
          border: '1px solid #ccc',
          background: 'white',
        }}>
        <div>
          <div style={{float: 'right'}}>{status[offer.status]}</div>
          <a href={offer.package?.link} target="_blank">
            {offer.package?.title}
          </a>

          {offer.package?.ibb && (
            <>
              &nbsp;<span className="badge badge-info">IBB</span>
            </>
          )}
          {offer.package?.noSell && (
            <>
              &nbsp;
              <span className="badge badge-warning">nicht verkaufbar</span>
            </>
          )}
        </div>
        <div>
          {offer.package?.months} Monate ({offer.package?.duration} Wochen){' '}
          {offer.price && (
            <div style={{float: 'right'}}>
              {hasSecondaryPayment ? (
                <>
                  <p style={{color: '#FF7C09'}}>
                    {payment?.primary?.amount?.toLocaleString('de-DE', {
                      currency: 'EUR',
                      style: 'currency',
                    })}{' '}
                    ({payment?.primary?.type})
                  </p>
                  <p style={{color: '#FF7C09'}}>
                    {payment?.secondary?.amount?.toLocaleString('de-DE', {
                      currency: 'EUR',
                      style: 'currency',
                    })}{' '}
                    ({payment?.secondary?.type})
                  </p>
                </>
              ) : (
                <p style={{color: '#FF7C09'}}>
                  {' '}
                  {offer.price?.toLocaleString('de-DE', {
                    currency: 'EUR',
                    style: 'currency',
                  })}
                </p>
              )}
            </div>
          )}
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '6px',
            marginTop: '1em',
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}>
          <div>
            <strong>Enthaltene Lehrgänge</strong>
            {offer.package?.components?.map(com => {
              return (
                <div key={com} style={{paddingLeft: '6px'}}>
                  {com}
                </div>
              );
            })}
          </div>
          <div>
            <p>
              <strong>Dokumente</strong>
            </p>
            <p>
              <a
                href="#"
                onClick={ev => {
                  ev.preventDefault();
                  downloadOffer();
                }}>
                <i className="fa fa-download"></i>{' '}
                {realOffer ? 'Angebot' : 'Bildungsempfehlung'}
              </a>
            </p>

            <div hidden={!isAccepted}>
              <p hidden={true}>
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    //setDoDownload('qualificationplan');
                  }}>
                  <i className="fa fa-download"></i> Qualifikationsplan
                </a>
              </p>
              <p hidden={true}>
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    //setDoDownload('courseplan');
                  }}>
                  <i className="fa fa-download"></i> Kursplan
                </a>
              </p>
              <p>
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    downloadContract();
                  }}>
                  <i className="fa fa-download"></i> Vertrag
                </a>
              </p>
              <p>
                <a href="/assets/datenschutz-teilnehmer.pdf" target="_blank">
                  <i className="fa fa-download"></i> Datenschutzhinweise
                </a>
              </p>
            </div>
            <br />
            <p
              hidden={offer.status === 'accepted' || offer.status === 'signed'}
              style={{margin: 0}}>
              <button
                onClick={ev => {
                  doRemoveOffer(ev, offer);
                }}
                className="kt-btn kt-secondary">
                Entfernen
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
