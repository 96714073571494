import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import useUser from '../../user/useUser';

/**
 * Make sure the route can be accessed only if the user is logged out
 */
const AnonymousRoute: React.FC<RouteProps> = props => {
  const {children, component: PageComponent, ...rest} = props;
  let user = useUser();

  return (
    <Route
      {...rest}
      render={pageProps => {
        //the user is still loading
        if (user === undefined) {
          //so don't show anything yet
          //until we know what the user is logged in (or not)
          return null;
        }

        //there is a user
        //but we are not expecting one for this route
        if (user !== null) {
          return <Redirect to="/" />;
        }

        //the page is sent through the 'component' property
        if (PageComponent) {
          return <PageComponent {...pageProps} />;
        }

        //the user is anonymous
        //so let him access the routes
        return <>{props.children}</>;
      }}
    />
  );
};

export default AnonymousRoute;
