import React from 'react';
import {Link} from 'react-router-dom';
import {useLoadingState} from '../../partials/LoadingProvider';

const LoggedOutView: React.FC = () => {
  useLoadingState();

  return (
    <div className="Home">
      <main className="container-fluid pt-5" role="main">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 mb-5">
              <h1>Du hast dich abgemeldet</h1>
              <p>
                Du kannst dieses Fenster schließen oder dich wieder{' '}
                <Link to="/">anmelden</Link>
              </p>
              <div>
                <Link to={`/`} className="kt-btn kt-primary mb-4">
                  Anmelden
                </Link>
              </div>
            </div>
            <div className="col col-12 col-md-6 text-center d-none d-md-block ">
              <img src="/images/exit-illustration.png" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoggedOutView;
