import React from 'react';
import {useLoadingState} from '../../partials/LoadingProvider';
import Login from './login';
import Register from './register';

const AuthView: React.FC = () => {
  useLoadingState();

  return (
    <div className="Home">
      <main className="container-fluid pt-5 pb-5" role="main">
        <div className="container">
          <h1 className="mb-4">Willkommen bei karriere tutor&reg;</h1>
          <div className="row align-items-start">
            <Login />

            <Register />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AuthView;
