import React from 'react';
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  errorExchange,
  Provider,
  CombinedError,
} from 'urql';
//import {retryExchange} from '@urql/exchange-retry';
//import {refocusExchange} from '@urql/exchange-refocus';

const options = {
  initialDelayMs: 10,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: (err: CombinedError) => err?.networkError !== undefined ?? false,
};

const client = createClient({
  url:
    process.env.REACT_APP_GRAPHQL_URL + '/graphql',
  fetchOptions: {
    credentials: 'include',
  },
  exchanges: [
    dedupExchange,
    //refocusExchange(),
    cacheExchange,
    errorExchange({
      onError: error => {
        // TODO document.location.reload()
        if (error?.response?.status === 401) {
          if (
            !('' + document.location).match(/logout/) &&
            !('' + document.location).match(/willkommen-bei-karriere-tutor/)
          ) {
            // disabled, handled by login/registration below
            // const url = config.authUrl || defaultAuthUrl;
            // window.location.assign((url + (url.includes('?')?'':'?') + '&redirect_url=' + encodeURIComponent(window.document.location)));
          }
        }
      },
    }),
    //retryExchange(options),
    fetchExchange,
  ],
});

const GraphQLProvider: React.FC = props => {
  return <Provider value={client}>{props.children}</Provider>;
};

export default GraphQLProvider;
