import React, {useState} from 'react';
import RegisterView from './register-view';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from 'formik';
import {RegisterFormValues} from './types';
import axios from 'axios';

const RegisterContainer: React.FC = () => {
  const [registrationDone, setRegistrationDone] = useState(false);

  var landing: string | null = '';

  try {
    let params = new URLSearchParams(document.location.search.substring(1));
    landing = params.get('landing');
  } catch (e) {
    console.log(e);
  }

  //
  // form validation rules
  //
  const validationSchema = Yup.object().shape({
    salutation: Yup.string().required('Bitte füllen Sie dieses Feld aus'),
    email: Yup.string()
      .email('Bitte geben Sie eine gültige E-Mail ein')
      .required('Bitte füllen Sie dieses Feld aus'),
    firstName: Yup.string().required('Bitte füllen Sie dieses Feld aus'),
    lastName: Yup.string().required('Bitte füllen Sie dieses Feld aus'),
    password: Yup.string()
      .required('Bitte füllen Sie dieses Feld aus')
      .matches(
        /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/,
        'Das Passwort muss 8-16 Zeichen lang sein, Kleinbuchstaben, Großbuchstaben, Ziffern [0-9] und mindestens ein Sonderzeichen (z.B: @ # $ %)',
      ),
    passwordConfirm: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        'Die beiden Passwörter stimmen nicht überein.',
      )
      .required('Bitte füllen Sie dieses Feld aus'),
    termsAccepted: Yup.boolean().isTrue('Sie müssen die AGB akzeptieren'),
  });

  //
  // Finalize the form
  //
  async function handleSubmit(
    values: RegisterFormValues,
    actions: FormikHelpers<RegisterFormValues>,
  ) {
    try {
      await axios.post(process.env.REACT_APP_SERVER + '/api/register', {
        salutation: values.salutation,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        termsAccepted: values.termsAccepted,
        newsletter: values.newsletter,
        landing: landing,
        privacyAccepted: true,
      });

      setRegistrationDone(true);
    } catch (e) {
      console.log(e);
      actions.setFieldError(
        'email',
        'Es gab einen Fehler bei der Registrierung. Bitte versuch es später noch einmal.',
      );
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        salutation: '',
        termsAccepted: false,
        newsletter: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formikProps => (
        <RegisterView {...formikProps} registrationDone={registrationDone} />
      )}
    </Formik>
  );
};

export default RegisterContainer;
