import React from 'react';
import {ActivateAccountViewProps} from './types';
import {ErrorMessage, Field, Form} from 'formik';
import {Link} from 'react-router-dom';
import cn from 'classnames';

const ActivateAccountView: React.FC<ActivateAccountViewProps> = props => {
  const {activationDone, isSubmitting, errors, touched, landing} = props;
  return (
    <main className="Home container-fluid pt-5" role="main">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h1>karriere tutor® Account aktivieren</h1>
            {!activationDone ? (
              <>
                <p className="mb-0">
                  Aus Sicherheitsgründen führen wir eine passwortbasierte
                  Bestätigung durch.
                </p>
                <p className="mb-0">
                  Bitte geben Sie hier Ihr Passwort ein, das Sie bei der
                  Registrierung vergeben haben.
                </p>
                <div className="row">
                  <div className="col-12 col-md-10 mt-0">
                    <Form className="needs-validation ">
                      <div
                        className={cn('kt-form', {
                          error: touched.password && errors.password,
                        })}>
                        <div className="md-form input-with-pre-icon pre-icon md-outline">
                          <i className="fas fa-key input-prefix"></i>
                          <i className="fas fa-exclamation-circle input-prefix input-error"></i>
                          <Field
                            type="password"
                            name="password"
                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                          />
                          <label>Passwort:</label>
                        </div>
                        <ErrorMessage
                          name="password"
                          render={msg => (
                            <div className="error-message">{msg}</div>
                          )}
                        />
                      </div>

                      <div className="submit-row">
                        <input
                          className="kt-btn kt-primary"
                          type="submit"
                          value={
                            isSubmitting ? 'Wird bearbeitet...' : 'Aktivieren!'
                          }
                          disabled={isSubmitting}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="alert alert-success" role="alert">
                  Account aktiviert!
                </div>
                <p>
                  Sie können sich jetzt{' '}
                  <Link to={'/' + encodeURIComponent(landing || '')}>
                    einloggen
                  </Link>
                  .
                </p>
              </div>
            )}
          </div>
          <div className="col col-12 col-md-6 text-center d-none d-md-block">
            <img src="/images/user-illustration.png" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ActivateAccountView;
