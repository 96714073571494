import React from 'react';
import {Route} from 'react-router-dom';
import useUser from '../../user/useUser';
import LoginRegistration from '../pages/LoginRegistration';
import NotFound from '../pages/NotFound';
import {PrivateRouteProps} from './types';

/**
 * Make sure the route can be accessed only if the user is logged in
 */
const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const {
    children,
    component: PageComponent,
    canAccess = () => true, //if not specified otherwise, all logged in users can access this route
    ...rest
  } = props;
  let user = useUser();

  return (
    <Route
      {...rest}
      render={pageProps => {
        //the user is still loading
        if (user === undefined) {
          //so don't show anything yet
          //until we know what the user is logged in (or not)
          return null;
        }

        //the user is unauthenticated
        //so show the login page, but on this current url
        if (user === null) {
          return <LoginRegistration />;
        }

        // check if the user should have access to this page
        if (!canAccess(user)) {
          return <NotFound />;
        }

        //the page is sent through the 'component' property
        if (PageComponent) {
          return <PageComponent {...pageProps} />;
        }

        //the user is anonymous
        //so let him access the routes
        return <>{props.children}</>;
      }}
    />
  );
};

export default PrivateRoute;
