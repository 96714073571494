import React from 'react';

const FooterView: React.FC = () => {
    return (
        <div className="Footer">
            <footer className="container-fluid">
                <div className="row justify-content-center justify-content-lg-between w-100 h-100 align-items-center">
                    <div className="col-12 col-lg-6 social">

                        <a href="https://www.facebook.com/karrieretutor/" rel="noindex,nofollow" target="_blank"
                           title="Karriere Tutor Facebook"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/karriere_tutor/" rel="noindex,nofollow" target="_blank"
                           title="Karriere Tutor Instagram"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/karriere-tutor-gmbh/" rel="noindex,nofollow"
                           target="_blank"
                           title="Karriere Tutor LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                        <a href="https://www.xing.com/pages/karrieretutor" rel="noindex,nofollow" target="_blank"
                           title="Karriere Tutor X!ng"><i className="fab fa-xing-square"></i></a>

                    </div>

                    <div className="col-12 col-lg-6 bottomnav">
                        <div className="menu-footer-menu-container">
                            <ul id="menu-footer-menu" className="menu">
                                <li id="menu-item-32"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32">
                                    <a href="https://www.karrieretutor.de/impressum/">Impressum</a></li>
                                <li id="menu-item-33"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-33">
                                    <a href="https://www.karrieretutor.de/agb/">AGB</a></li>
                                <li id="menu-item-34"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-34">
                                    <a href="https://www.karrieretutor.de/datenschutz/">Datenschutz</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default FooterView;
