import React, {useState} from 'react';
import {ViewProps} from './types';
import {DateTime} from 'luxon';

const fix = (what: any) => (what <= 9 ? '0' + what : what);
const formatDate = (what: Date) => {
  return [
    what.getFullYear(),
    fix(what.getMonth() + 1),
    fix(what.getDate()),
  ].join('-');
};

const View: React.FC<ViewProps> = props => {
  const {potential, onUpload, onSetStartDate, isSales} = props;

  const start = potential.start
    ? new Date(potential.start)
    : DateTime.fromJSDate(new Date())
        .startOf('week')
        .plus({weeks: 2, days: 3})
        .toJSDate();

  const [haveEignung, setClickedEignung] = useState(potential.haveEignung);
  const [haveKBEignung, setClickedKBEignung] = useState(
    potential.haveKBEignung,
  );
  const [haveBGS, setHaveBGS] = useState(potential.haveBGS);
  const [haveCV, setHaveCV] = useState(potential.haveCV);
  const [startDate, setStartDate] = useState(formatDate(start));

  const doUpload1 = onUpload as any;
  const doUpload = (type: string, ev: any) => {
    doUpload1(type, ev);

    if (type === 'bgs') setHaveBGS(true);
    if (type === 'cv') setHaveCV(true);
  };

  const doSetStartDate = (what: any) => {
    setStartDate(what);
    onSetStartDate(what);
  };

  return (
    <div>
      <p hidden={isSales}>
        <strong>In wenigen Schritten zu Ihrer Weiterbildung</strong>
      </p>
      <table>
        <tbody>
          <tr>
            <td style={{paddingRight: '1rem'}}>1.</td>
            <td style={{paddingRight: '1rem'}}>
              <span hidden={!haveCV}>
                <i className="fa fa-check" style={{color: 'green'}}></i>{' '}
                Lebenslauf
              </span>
              <span hidden={haveCV}>Lebenslauf hochladen</span>
            </td>
            <td style={{paddingRight: '1rem'}}>
              <span hidden={haveCV || isSales}>
                <input
                  className="form-control"
                  style={{width: 'auto', display: 'inline'}}
                  type="file"
                  onChange={ev => doUpload('cv', ev)}
                />
              </span>
            </td>
          </tr>
          <tr>
            <td style={{paddingRight: '1rem'}}>2.</td>
            <td style={{paddingRight: '1rem'}}>
              <span hidden={!haveBGS}>
                <i className="fa fa-check" style={{color: 'green'}}></i>{' '}
                Bildungsgutschein
              </span>
              <span hidden={haveBGS}>Bildungsgutschein hochladen</span>
            </td>
            <td style={{paddingRight: '1rem'}}>
              <span hidden={haveBGS || isSales}>
                <input
                  className="form-control"
                  style={{width: 'auto', display: 'inline'}}
                  type="file"
                  onChange={ev => doUpload('bgs', ev)}
                />
              </span>
            </td>
          </tr>
          <tr>
            <td style={{paddingRight: '1rem'}}>3.</td>
            <td style={{paddingRight: '1rem'}}>
              <span>
                <i className="fa fa-check" style={{color: 'green'}}></i>{' '}
                Starttermin:
              </span>{' '}
              <input
                disabled={isSales}
                onChange={ev => doSetStartDate(ev.target.value)}
                type="date"
                step="7"
                min="2021-05-20"
                className="form-control"
                value={start ? startDate : undefined}
              />
            </td>
            <td style={{paddingRight: '1rem'}}></td>
          </tr>
          <tr>
            <td style={{paddingRight: '1rem'}}>4.</td>
            <td style={{paddingRight: '1rem'}}>
              <span>
                <i
                  hidden={!haveEignung}
                  className="fa fa-check"
                  style={{color: 'green'}}></i>{' '}
                Eignung{' '}
              </span>
              <a
                hidden={haveEignung || isSales}
                href={
                  'https://survey.zohopublic.eu/zs/qghC8l?fromservice=mein&zs_potentials=' +
                  encodeURIComponent(potential.id)
                }
                onClick={() => setClickedEignung(true)}
                target="_blank">
                durchführen
              </a>
            </td>
            <td style={{paddingRight: '1rem'}}></td>
          </tr>
          <tr hidden={!isSales}>
            <td style={{paddingRight: '1rem'}}>5.</td>
            <td style={{paddingRight: '1rem'}}>
              <span hidden={!haveKBEignung}>
                <i className="fa fa-check" style={{color: 'green'}}></i>{' '}
                Karriereberatungseignung
              </span>
              <a
                hidden={haveKBEignung}
                href={
                  'https://survey.zohopublic.eu/zs/5lhRgH?fromservice=mein&zs_potentials=' +
                  encodeURIComponent(potential.id)
                }
                onClick={() => setClickedEignung(true)}
                target="_blank">
                Karriereberatungseignung durchführen
              </a>
            </td>
            <td style={{paddingRight: '1rem'}}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default View;
