import React from 'react';

import {Switch, Route, Redirect} from 'react-router-dom';
import UserProvider from '../user/UserProvider';
import GrapQLProvider from './partials/GraphQLProvider';
import NotFound from './pages/NotFound';
import CustomerPotentials from './pages/CustomerPotentials';
import Search from './pages/Search';
import Timetable from './pages/Timetable';
import './App.css';
import RootPage from './pages/RootPage';
import LoggedOut from './pages/LoggedOut';
import ActivateAccount from './pages/ActivateAccount';
import SigningDone from './pages/CustomerPotentials/signing';
import Footer from './partials/Footer';
import Header from './partials/Header';
import {HeaderProvider} from './partials/Header';
import AnonymousRoute from './utils/AnonymousRoute';
import PrivateRoute from './utils/PrivateRoute';
import {useTracking} from './utils/tracking';

const Educations = React.lazy(() => import('./pages/organization/Educations'));
const EducationsDashboard = React.lazy(
  () => import('./pages/organization/dashboard'),
);
const Classifications = React.lazy(
  () => import('./pages/organization/Classifications'),
);
const Classification = React.lazy(
  () => import('./pages/organization/Classification'),
);
const Keywords = React.lazy(() => import('./pages/organization/Keywords'));
const Courses = React.lazy(() => import('./pages/organization/Courses'));
const Course = React.lazy(() => import('./pages/organization/Course'));
const Education = React.lazy(() => import('./pages/organization/Education'));
const Keyword = React.lazy(() => import('./pages/organization/Keyword'));
const DeveloperHome = React.lazy(() => import('./pages/DeveloperHome'));

const App: React.FC = () => {
  useTracking('GTM-PF6H5T');

  return (
    <GrapQLProvider>
      <React.Suspense fallback={<></>}>
        <UserProvider>
          <HeaderProvider>
            <Switch>
              {/* Private Area - this routes can only be access if the user is logged in */}
              <PrivateRoute exact path="/" component={RootPage} />
              <PrivateRoute
                exact
                path="/course/:id/timetable"
                component={Timetable}
              />
              <PrivateRoute
                exact
                path="/search"
                component={Search}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('graph-admin') ||
                    me.roles?.includes('Karriereberatung') ||
                    me.roles?.includes('Sales Support') ||
                    me.roles?.includes('employee')
                    )
                }
              />

              <PrivateRoute
                exact
                path="/api"
                component={DeveloperHome}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/warenkorb"
                component={CustomerPotentials}
                canAccess={me => me.type === 'customer'}
              />

              <PrivateRoute
                exact
                path="/vertrag-abgeschlossen/"
                component={SigningDone}
                canAccess={me => me.type === 'customer'}
              />

              <PrivateRoute
                exact
                path="/beratung/:id"
                component={CustomerPotentials}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('graph-admin') ||
                    me.roles?.includes('Karriereberatung') ||
                    me.roles?.includes('Sales Support')) ||
                    me.roles?.includes('employee')
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations"
                component={Educations}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/dashboard"
                component={EducationsDashboard}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/classifications"
                component={Classifications}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/classification/:id"
                component={Classification}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/keywords"
                component={Keywords}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/courses"
                component={Courses}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/education/:id"
                component={Education}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/course/:id"
                component={Course}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              <PrivateRoute
                exact
                path="/organization/educations/keyword/:id"
                component={Keyword}
                canAccess={me =>
                  me.type === 'employee' &&
                  (me.roles?.includes('graph-developer') ||
                    me.roles?.includes('Product Manager Kursnet') ||
                    me.roles?.includes('Teamlead Course Conception') ||
                    me.roles?.includes('graph-admin'))
                }
              />

              {/* End Private Area */}

              {/* Anonymous Area */}
              <AnonymousRoute
                exact
                path="/:activationKey/willkommen-bei-karriere-tutor"
                component={ActivateAccount}
              />
              <AnonymousRoute exact path="/logout" component={LoggedOut} />
              {/* End  Anonymous Area */}

              <Route component={NotFound} />
            </Switch>
            <Footer />
          </HeaderProvider>
        </UserProvider>
      </React.Suspense>
    </GrapQLProvider>
  );
};

export default App;
