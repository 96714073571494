import {useState, useReducer} from 'react';
import React from 'react';
import HeaderContext from './header-context';
import {HeaderProviderProps, MenuItem} from './types';
import HeaderView from './header-view';
import useUser from '../../../user/useUser';

interface State {
  items: Array<MenuItem>;
}

interface Action {
  type: string;
  item: MenuItem;
}

function reduceItems(state: State, action: Action) {
  switch (action.type) {
    case 'add':
      let items = state.items;
      if (!items.some(item => item.id == action.item.id)) {
        items.push(action.item);
        state = {...state, items};
      }
      return state;
    default:
      throw new Error();
  }
}

/**
 *
 * Handle managing the overall app navigation
 *
 * @param {*} props
 */
const HeaderProvider: React.FC<HeaderProviderProps> = props => {
  const initial: State = {
    items: [],
  };

  const [state, dispatch] = useReducer(reduceItems, initial);

  const me = useUser();

  return (
    <>
      <HeaderView me={me} items={state.items} canSendFeedback={!!me} />
      <HeaderContext.Provider
        value={{
          addItem: (item: MenuItem) => {
            dispatch({type: 'add', item});
          },
        }}>
        {props.children}
      </HeaderContext.Provider>
    </>
  );
};

export default HeaderProvider;
