import React from 'react';
import {ErrorMessage, Field, Form} from 'formik';
import {LoginViewProps} from './types';
import cn from 'classnames';

const LoginView: React.FC<LoginViewProps> = props => {
  const {passwordResetUrl, loginEmployeeUrl, errors, touched} = props;
  return (
    <div className="kt-login kt-box col-12 col-md-5 py-4">
      <Form className="needs-validation">
        <div className="row px-2">
          <div
            className={cn('kt-form col-md-12', {
              error: touched.email && errors.email,
            })}>
            <h4 className="mb-0">Anmelden</h4>
            <div>Zugang zu „Mein karriere tutor®</div>
            <div className="md-form input-with-pre-icon pre-icon md-outline">
              <i className="fas fa-user input-prefix"></i>
              <i className="fas fa-exclamation-circle input-prefix input-error"></i>
              <Field
                type="email"
                name="email"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                aria-required="true"
                aria-invalid="false"
              />
              <label>Meine E-Mail</label>
            </div>
            <ErrorMessage
              name="email"
              render={msg => <div className="error-message mb-1">{msg}</div>}
            />
          </div>
        </div>

        <div className="row px-2">
          <div className="col-12 col-md-6">
            <input
              className="kt-btn kt-primary kt-xs-center"
              type="submit"
              value="Anmelden!"
            />
          </div>
          <div className="col-12 col-md-6 mt-1 text-right">
            <a
              href="https://graph.karrieretutor.de/login?redirect_url=https%3A%2F%2Fmein.karrieretutor.de%2F&realm=google"
              className="dataprotection-link">
              Anmelden mit Google
            </a>
            <br />
            <a href={passwordResetUrl} className="dataprotection-link">
              Passwort vergessen?
            </a>
            <br />
            <a href={loginEmployeeUrl} className="dataprotection-link">
              Mitarbeiter
            </a>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginView;
