import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import RequestFeedback from '../RequestFeedback';
import ToastContainer from '../ToastContainer';
import {HeaderViewProps} from './types';
import {useQuery} from 'urql';
import gql from 'graphql-tag';

const query = gql`
  query {
    serviceStatus {
      name
      status
      url
    }
  }
`;

const HeaderView: React.FC<HeaderViewProps> = props => {
    const {me, canSendFeedback} = props;

    const [showStatus, setShowStatus] = useState(false);

    const [{data, fetching, error}, reexecuteQuery] = useQuery({
        query: query,
        variables: {},
        pause: !me || me.type !== 'employee',
    });

    const haveError =
        data?.serviceStatus?.filter((item: any) => item.status !== 'ok').length > 0;

    return (
        <header>
            <ToastContainer/>
            <div className="container-fluid nav">
                <div className="container">
                    <div className="row">
                        <div className="col-5 col-lg-2 nav-brand">
                            <a href="https://www.karrieretutor.de/" title="karriere tutor®" className="navbar-brand"
                               id="logo">
                                <img src="/karriere-tutor-logo.jpg" alt="karriere tutor®"/>
                            </a>
                        </div>
                        {/*<div className="col-7 nav-mobile" hidden={!!me}>*/}
                        {/*    <div className="nav-icon" id="search-start">*/}
                        {/*        <i className="fas fa-search"></i>*/}
                        {/*    </div>*/}
                        {/*    <div className="nav-icon">*/}
                        {/*        <a href="tel:0800711410200">*/}
                        {/*            <i className="fas fa-phone"></i>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*    <div className="nav-icon">*/}
                        {/*        <i className="fas fa-user"></i>*/}
                        {/*    </div>*/}
                        {/*    <div className="nav-icon menu-start">*/}
                        {/*        <i className="fas fa-bars"></i>*/}
                        {/*    </div>*/}
                        {/*    <div className="nav-icon menu-end">*/}
                        {/*        <i className="fas fa-times actn-btn"></i>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="loggedIn-Nav nav-mobile ktnav d-flex ml-auto"
                             hidden={!me || me.type === 'employee'}>
                            {canSendFeedback && <RequestFeedback/>}

                            <div className="username px-3" style={{display: me?.name ? '' : 'none'}}>
                                <i className="fas fa-user"></i>
                                <span className="pl-2">{me?.name || me?.email}</span>
                            </div>

                            <div className="logout pl-3" style={{display: me?.name ? '' : 'none'}}>
                                <a href={`https://graph.karrieretutor.de/logout?redirect_url=${encodeURIComponent((window.location.origin ?? 'https://mein.karrieretutor.de') + '/logout',)}`}
                                   style={{color: '#1F2243', fontSize: '1rem'}}>
                                    <i className="fas fa-sign-out"></i>
                                    <span className="pl-2">Abmelden</span>
                                </a>
                            </div>
                        </div>

                        <div className="col-7 col-lg-10 align-self-center nav-desktop nav-menu">
                            <nav className="navbar navbar-expand-lg">
                                <div className="nav-items" id="header-menu">
                                    <ul
                                        role="menubar"
                                        id="menu-header-menu"
                                        className="navbar-nav">
                                        <li className="nav-item" hidden={!!me}>
                                            <a
                                                href="https://www.karrieretutor.de/"
                                                className="nav-link waves-effect waves-light">
                                                <i className="fas fa-chevron-left"></i>{' '}
                                                zur&uuml;ck zur Webseite
                                            </a>
                                        </li>

                                        {props.items.map(item => (
                                            <li key={item.id} className="nav-item">
                                                <a
                                                    href={item.href}
                                                    className="nav-link waves-effect waves-light">
                                                    {item.icon && <i className={item.icon}></i>}{' '}
                                                    {item.label}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    {' '}
                                </div>
                                <div
                                    hidden={
                                        !me || !(me != null && (me as any).type === 'employee')
                                    }>
                  <span hidden={!data}>
                    <i
                        className="fas fa-check-circle"
                        style={{color: 'green'}}
                        hidden={haveError}></i>
                    <i
                        className="fas fa-exclamation-triangle"
                        style={{color: '#cc0000'}}
                        hidden={!haveError}></i>
                    <a
                        onClick={() => setShowStatus(!showStatus)}
                        className="nav-icon"
                        style={{color: '#1F2243', fontSize: '1rem'}}>
                      &nbsp;Status
                    </a>
                  </span>
                                    <div
                                        style={{
                                            padding: '1.4rem',
                                            position: 'absolute',
                                            top: '1rem',
                                            marginLeft: '-22px',
                                            background: 'white',
                                        }}
                                        className="kt-box"
                                        hidden={!showStatus}>
                                        {data?.serviceStatus?.map((item: any) => (
                                            <div key={item.name}>
                                                <i
                                                    className="fas fa-check-circle"
                                                    style={{color: 'green'}}
                                                    hidden={item.status !== 'ok'}></i>
                                                <i
                                                    className="fas fa-exclamation-triangle"
                                                    style={{color: '#cc0000'}}
                                                    hidden={item.status === 'ok'}></i>
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    className="nav-icon"
                                                    style={{color: '#1F2243', fontSize: '1rem'}}>
                                                    &nbsp;{item.name}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    &nbsp;&nbsp;
                                    <i className="fas fa-chalkboard-teacher"></i>
                                    <a
                                        href="https://karrieretutor.avendoo.de/l/samlLogin?sso=samlUser"
                                        target="_blank"
                                        className="nav-icon"
                                        style={{color: '#1F2243', fontSize: '1rem'}}>
                                        &nbsp;avendoo
                                    </a>
                                </div>
                                &nbsp;

                                <div className="loggedIn-Nav ktnav d-flex" hidden={!me || me.type === 'employee'}>
                                    {canSendFeedback && <RequestFeedback/>}

                                    <div className="username px-3" style={{display: me?.name ? '' : 'none'}}>
                                        <i className="fas fa-user"></i>
                                        <span className="pl-2">{me?.name || me?.email}</span>
                                    </div>

                                    <div className="logout pl-3" style={{display: me?.name ? '' : 'none'}}>
                                        <a href={`https://graph.karrieretutor.de/logout?redirect_url=${encodeURIComponent((window.location.origin ?? 'https://mein.karrieretutor.de') + '/logout',)}`}
                                           style={{color: '#1F2243', fontSize: '1rem'}}>
                                            <i className="fas fa-sign-out"></i>
                                            <span className="pl-2">Abmelden</span>
                                        </a>
                                    </div>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderView;
