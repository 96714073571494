import {useContext} from 'react';
import {UserContext} from '../context/user';
import {User} from './types';

export default function useUser() {
  return useContext(UserContext);
}

/**
 * Check if the user is loading
 *
 * Loading = undefined
 * Anonymous = null
 * User = logged in
 */
export function isLoading(user: User | null | undefined): user is undefined {
  return user === undefined;
}
