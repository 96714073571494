import invariant from 'invariant';
import React from 'react';
import useUser from '../../../user/useUser';
import EmployeeHome from '../EmployeeHome';
import Home from '../Home';
import InvitedHome from '../InvitedHome';
import {Redirect} from 'react-router-dom';

const RootPageContainer: React.FC = () => {
  const me = useUser();
  invariant(me, 'Need to protect this with a <PrivateRoute> component');

  const experimental = (document.location.hash + '').includes('beta');

  if (experimental && !me.hasActiveBooking && me.type === 'customer') {
    return <Redirect to="/warenkorb/" />;
  }

  switch (me.type) {
    case 'employee': {
      return <EmployeeHome />;
    }
    case 'customer': {
      switch (me.status) {
        case 'registered':
          return <Home />;
        case 'invited':
          return <InvitedHome />;
      }
    }
  }

  //just in case none of them is
  //show an empty screen ?
  return null;
};

export default RootPageContainer;
