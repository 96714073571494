import React from 'react';
import gql from 'graphql-tag';
import {useQuery} from 'urql';
import {useLoadingState} from '../../partials/LoadingProvider';

import './Home.css';
import HomeView from './home-view';
import useUser, {isLoading} from '../../../user/useUser';

const HomeContainer: React.FC = props => {
  const user = useUser();

  useLoadingState(isLoading(user));

  return <HomeView />;
};

export default HomeContainer;
